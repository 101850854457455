<template>
  <div>
    <div class="flex justify-between">
      <h2 class="text-lg leading-6 font-medium text-gray-900">Driver's Reimbursements</h2>
    </div>
    <div class="border border-gray-300 p-2">
      <div class="border-b border-gray-300 mb-4 last:border-b-0 last:mb-0">
        <reimbursement 
          :reimbursement="reimbursement"
          :odometersAvailable="odometersAvailable"
          @onSuccess="displaySuccessNotification"
          @onFailure="displayFailureNotification"
        >
        </reimbursement>
      </div>
    </div>

    <modal-expense-reimbursement
      :isVisible="modalExpenseReimbursementVisible"
      :reimbursementId="reimbursementIdForExpense"
      @closeDialog="hideModalExpenseReimbursement"
      @confirmDialog="onAddExpense"
    >
    </modal-expense-reimbursement>

    <template v-if="showSuccessNotification">
      <transition
        enter-active-class="transition ease-out duration-400 transform"
        enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <notification-success>Your changes have been saved</notification-success>
      </transition>
    </template>

    <template v-if="showFailureNotification">
      <transition
        enter-active-class="transition ease-out duration-400 transform"
        enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <notification-failure>{{ errorMessages }}</notification-failure>
      </transition>
    </template>

  </div>
</template>

<script>
import modalExpenseReimbursement from './modal-expense-reimbursement';
import notificationSuccess from './../../../shared-components/notifications/notification-success';
import notificationFailure from './../../../shared-components/notifications/notification-failure';
import reimbursement from './reimbursement.vue';
import gql from 'graphql-tag';

const createReimbursementExpenseMutation = gql`
mutation createReimbursementExpense($input: CreateReimbursementExpenseInput!) {
  createReimbursementExpense(input: $input) {
    reimbursement {
      id
      reimbursementExpenses {
        id
        amount
        comment
      }
      expenseReimbursementDisplay
      totalDisplay
    }
    errors
  }
}
`;

const deleteReimbursementMutation = gql`
mutation deleteReimbursementExpense($input: DeleteReimbursementExpenseInput!) {
  deleteReimbursementExpense(input: $input) {
    reimbursement {
      id
      reimbursementExpenses {
        id
      }
      expenseReimbursementDisplay
      totalDisplay
    }
    errors
  }
}
`;

export default {
  components: { modalExpenseReimbursement, notificationSuccess, notificationFailure, reimbursement },
  props: {
    reimbursement: {
      type: Object,
      required: true,
    },
    odometersAvailable: {
      type: Boolean,
      required: true
    },
  },
  data: () => ({
      reimbursementIdForExpense: '',
      modalExpenseReimbursementVisible: false,
      showSuccessNotification: false,
      showFailureNotification: false,
      errorMessages: [],
  }),
  methods: { // TODO: remove unused methods
    displaySuccessNotification() {
      this.showSuccessNotification = true;
      setTimeout(() => this.showSuccessNotification = false, 3000);
    },
    displayFailureNotification(errArray) {
      this.errorMessages = errArray;
      this.showFailureNotification = true;
      setTimeout(() => this.showFailureNotification = false, 5000);
    },
    hideModalExpenseReimbursement() {
      this.reimbursementIdForExpense = ''
      this.modalExpenseReimbursementVisible = false
    },
    showModalExpenseReimbursement(id) {
      this.reimbursementIdForExpense = id
      this.modalExpenseReimbursementVisible = true
    },
    onAddExpense(obj) {
      const { reimbursementId, ...expense } = obj
      this.$apollo.mutate({
        mutation: createReimbursementExpenseMutation,
        variables: {
          input: {
            id: reimbursementId,
            reimbursementExpense: expense
          }
        }
      }).then((response) => {
        const err = response.errors || response.data.createReimbursementExpense.errors
        if (err.length) {
          console.error(err)
          this.displayFailureNotification(err);
          return
        }

        this.displaySuccessNotification();
      }).catch(error => {
        console.error(error)
        this.displayFailureNotification(error);
      })
    },
    removeExpense(reimbursementId, expenseId) {
      this.$apollo.mutate({
        mutation: deleteReimbursementMutation,
        variables: {
          input: {
            reimbursementId: reimbursementId,
            reimbursementExpenseId: expenseId,
          }
        }
      }).then((response) => {
        const err = response.errors || response.data.deleteReimbursementExpense.errors
        if (err.length) {
          console.error(err)
          this.displayFailureNotification(err);
          return
        }
        
        this.displaySuccessNotification();
      }).catch(error => {
        console.error(error)
        this.displayFailureNotification(error);
      })
    },
  },
}
</script>
