<template>
  <div class="divide-y divide-gray-300">

     <div :class="dataRowBtnContainer">
      <button
        @click="enableEdit()"
        v-show="!isEditEnabled"
        type="button"
        :class="editSaveBtn">
        Edit Client Data
      </button>
      <button
        @click="cancelEdit()"
        v-show="isEditEnabled"
        type="button"
        :class="cancelBtn"
        class="mr-3"
      >
        Cancel
      </button>
      <button
        @click="save()"
        v-show="isEditEnabled"
        type="button"
        :class="editSaveBtn">
        Save
      </button>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Medicaid Number</div>
      <div :class="dataRowContent">
        <input
          type="text"
          :disabled="!isEditEnabled"
          :class="isEditEnabled? inputFieldActive : inputFieldInactive"
          v-model.trim="person.medicaidNumber" />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Medicaid County</div>
      <div :class="dataRowContent">
        <input
          type="text"
          :disabled="!isEditEnabled"
          :class="isEditEnabled? inputFieldActive : inputFieldInactive"
          v-model.trim="person.medicaidCounty" />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Is Bariatric?</div>
      <div :class="dataRowContent">
        <input
          type="checkbox"
          :disabled="!isEditEnabled"
          :class="isEditEnabled ? checkboxActive : inputFieldInactive"
          v-model.trim="person.isBariatric" />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Is Frail Or Disabled?</div>
      <div :class="dataRowContent">
        <input
          type="checkbox"
          :disabled="!isEditEnabled"
          :class="isEditEnabled ? checkboxActive : inputFieldInactive"
          v-model.trim="person.isFrailOrDisabled" />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Is A Veteran?</div>
      <div :class="dataRowContent">
        <input
          type="checkbox"
          :disabled="!isEditEnabled"
          :class="isEditEnabled ? checkboxActive : inputFieldInactive"
          v-model.trim="person.isVeteran" />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Lives Alone?</div>
      <div :class="dataRowContent">
        <input
          type="checkbox"
          :disabled="!isEditEnabled"
          :class="isEditEnabled ? checkboxActive : inputFieldInactive"
          v-model.trim="person.livesAlone" />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Requires Door To Door Assistance?</div>
      <div :class="dataRowContent">
        <input
          type="checkbox"
          :disabled="!isEditEnabled"
          :class="isEditEnabled ? checkboxActive : inputFieldInactive"
          v-model.trim="person.needsDoorToDoorAssistance" />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Requires Assistance Entering/Exiting Vehicle?</div>
      <div :class="dataRowContent">
        <input
          type="checkbox"
          :disabled="!isEditEnabled"
          :class="isEditEnabled ? checkboxActive : inputFieldInactive"
          v-model.trim="person.needsVehicleAssistance" />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Requires Wheelchair Transportation?</div>
      <div :class="dataRowContent">
        <input
          type="checkbox"
          :disabled="!isEditEnabled"
          :class="isEditEnabled ? checkboxActive : inputFieldInactive"
          v-model.trim="person.needsWheelchairVehicle" />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Uses A Wheelchair?</div>
      <div :class="dataRowContent">
        <input
          type="checkbox"
          :disabled="!isEditEnabled"
          :class="isEditEnabled ? checkboxActive : inputFieldInactive"
          v-model.trim="person.usesWheelchair" />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Uses A Walker?</div>
      <div :class="dataRowContent">
        <input
          type="checkbox"
          :disabled="!isEditEnabled"
          :class="isEditEnabled ? checkboxActive : inputFieldInactive"
          v-model.trim="person.usesWalker" />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Uses An Oxygen Tank?</div>
      <div :class="dataRowContent">
        <input
          type="checkbox"
          :disabled="!isEditEnabled"
          :class="isEditEnabled ? checkboxActive : inputFieldInactive"
          v-model.trim="person.usesOxygenTank" />
      </div>
    </div>

    <template v-if="showSuccessNotification">
      <transition
        enter-active-class="transition ease-out duration-400 transform"
        enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <notification-success>User data has been updated</notification-success>
      </transition>
    </template>

  </div>
</template>

<script>
import notificationSuccess from './../../../shared-components/notifications/notification-success';
import gql from 'graphql-tag';

const updatePersonMutation = gql`
mutation updatePerson($input: UpdatePersonInput!) {
  updatePerson(input: $input) {
    person {
      id
      medicaidNumber
      medicaidCounty
      isBariatric
      isFrailOrDisabled
      isVeteran
      livesAlone
      needsDoorToDoorAssistance
      needsVehicleAssistance
      needsWheelchairVehicle
      usesWheelchair
      usesWalker
      usesOxygenTank
    }
    errors {
      message
      path
    }
  }
}
`;

export default {
  components: { notificationSuccess },
  props: {
    person: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    isEditEnabled: false,
    showSuccessNotification: false,
  }),
  computed: {
    dataRowContainer() {
      return ['grid', 'grid-cols-4', 'gap-4', 'p-2', 'flex', 'items-center']
    },
    dataRowTitle() {
      return ['col-span-1', 'leading-5', 'text-sm', 'text-gray-500', 'font-medium']
    },
    dataRowContent() {
      return ['col-span-2', 'leading-5', 'text-sm', 'text-gray-900', 'font-medium']
    },
    dataRowBtnContainer() {
      return ['col-span-1', 'flex', 'justify-end', 'py-2']
    },
    inputFieldActive() {
      return ['form-input', 'block', 'w-full', 'text-sm', 'leading-5']
    },
    checkboxActive() {
      // omit form-input for checkboxes
      return ['block', 'w-full', 'text-sm', 'leading-5']
    },
    inputFieldInactive() {
      return ['bg-white', 'block', 'w-full', 'text-sm', 'leading-5']
    },
    editSaveBtn() {
      return ['shadow-sm', 'rounded', 'border', 'border-gray-300', 'bg-white', 'px-2', 'py-1', 'inline-flex', 'items-center', 'text-xs', 'leading-3', 'font-medium', 'text-gray-700', 'hover:text-gray-500', 'focus:outline-none', 'active:bg-gray-100', 'active:text-gray-700', 'transition', 'ease-in-out', 'duration-150']
    },
    cancelBtn() {
      return ['px-2', 'py-1', 'inline-flex', 'items-center', 'text-xs', 'leading-3', 'font-medium', 'text-red-600', 'hover:underline', 'focus:outline-none', 'active:text-red-800', 'transition', 'ease-in-out', 'duration-150']
    },
  },
  methods: {
    cancelEdit: function() {
      this.isEditEnabled = false;
    },
    enableEdit: function() {
      this.isEditEnabled = true;
    },
    save: function() {
      this.$apollo.mutate({
        mutation: updatePersonMutation,
        variables: {
          input: this.buildInput(),
        }
      }).then((response) => {
        const err = response.data.errors ??
                    response.data?.updatePerson?.errors;
        if (err.length) {
          console.error(err);
          return;
        }

        this.isEditEnabled = false;
        this.showSuccessNotification = true;
        setTimeout(() => this.showSuccessNotification = false, 3000);
      })
    },
    buildInput: function() {
      return {
        id: this.person.id,
        person: {
          medicaidNumber: this.person.medicaidNumber,
          medicaidCounty: this.person.medicaidCounty,
          isBariatric: this.person.isBariatric,
          isFrailOrDisabled: this.person.isFrailOrDisabled,
          isVeteran: this.person.isVeteran,
          livesAlone: this.person.livesAlone,
          needsDoorToDoorAssistance: this.person.needsDoorToDoorAssistance,
          needsVehicleAssistance: this.person.needsVehicleAssistance,
          needsWheelchairVehicle: this.person.needsWheelchairVehicle,
          usesWheelchair: this.person.usesWheelchair,
          usesWalker: this.person.usesWalker,
          usesOxygenTank: this.person.usesOxygenTank,
        }
      };
    },
  },
}
</script>