<template>
  <div class="divide-y divide-gray-300">
    <div :class="dataRowBtnContainer">
      <button
        @click="enableEdit()"
        v-show="!isEditEnabled"
        type="button"
        :class="editSaveBtn">
        Edit Demographic Data
      </button>
      <button
        @click="cancelEdit()"
        v-show="isEditEnabled"
        type="button"
        :class="cancelBtn"
        class="mr-3"
      >
        Cancel
      </button>
      <button
        @click="save()"
        v-show="isEditEnabled"
        type="button"
        :class="editSaveBtn">
        Save
      </button>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Ethnicity</div>
      <div :class="dataRowContent">
        <!-- TODO: calling #update on model expects *application* Ethnicity,
             not GraphQL Ethnicity -->
        <FormulateInput
          v-model="person.ethnicities"
          :options="ethnicityOptions"
          :reduce="ethnicity => ethnicity.value"
          :multiple="true"
          :disabled="true"
          :input-class="(context, classes) => [isEditEnabled? [inputFieldActive, 'bg-gray-200'] : inputFieldInactive].concat(classes)"
          title="Changing ethnicity is unavailable, feature will be added soon."
        />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Gender</div>
      <div v-if="isEditEnabled" :class="dataRowContent">
        <FormulateInput
          type="select"
          :options="genderOptions"
          :disabled="!isEditEnabled"
          :input-class="selectFieldActive"
          v-model="person.gender" />
      </div>
      <div v-else :class="dataRowContent">{{ person.gender }}</div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Marital Status</div>
      <div v-if="isEditEnabled" :class="dataRowContent">
        <FormulateInput
          type="select"
          :options="martialStatusOptions"
          :disabled="!isEditEnabled"
          :input-class="selectFieldActive"
          v-model="person.maritalStatus" />
      </div>
      <div v-else :class="dataRowContent">{{ person.maritalStatus }}</div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Monthly Income</div>
      <div :class="dataRowContent" class="relative">
        <div class="absolute inset-y-0 left-0 pl-1 flex items-center pointer-events-none">
          <span class="text-gray-500 sm:text-sm sm:leading-5">
            $
          </span>
        </div>
        <FormulateInput
          type="number"
          :disabled="!isEditEnabled"
          :input-class="(context, classes) => [isEditEnabled? inputFieldActive : inputFieldInactive].concat(classes)"
          class="pl-5"
          v-model.trim="person.monthlyIncome" />
      </div>
    </div>

    <template v-if="showSuccessNotification">
      <transition
        enter-active-class="transition ease-out duration-400 transform"
        enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <notification-success>User data has been updated</notification-success>
      </transition>
    </template>

  </div>
</template>

<script>
import notificationSuccess from './../../../shared-components/notifications/notification-success';
import gql from 'graphql-tag';
import vSelect from 'vue-select';

const updatePersonMutation = gql`
mutation updatePerson($input: UpdatePersonInput!) {
  updatePerson(input: $input) {
    person {
      id
    }
    errors {
      message
      path
    }
  }
}
`;

export default {
  components: { notificationSuccess, vSelect },
  props: {
    person: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    isEditEnabled: false,
    showSuccessNotification: false,
    ethnicityOptions: [
        { value: "AMERICAN_INDIAN_OR_ALASKA_NATIVE", label: "American Indian or Alaska Native" },
        { value: "ASIAN", label: "Asian" },
        { value: "BLACK_OR_AFRICAN_AMERICAN", label: "Black or African American" },
        { value: "HISPANIC_LATINO_OR_SPANISH_ORIGIN", label: "Hispanic, Latino or Spanish origin" },
        { value: "MIDDLE_EASTERN_OR_NORTH_AFRICAN", label: "Middle Eastern or North African" },
        { value: "NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER", label: "Native Hawaiian and Other Pacific Islander" },
        { value: "WHITE", label: "White" },
        { value: "OTHER_RACE_OR_ETHNICITY", label: "Other race or ethnicity" },
        { value: "DECLINE_TO_STATE", label: "Decline to state" },
    ],
    genderOptions: {
      FEMALE: "Female",
      MALE: "Male",
      DECLINE_TO_STATE: "Decline to state",
    },
    martialStatusOptions: {
      SINGLE: "Single",
      MARRIED: "Married",
      DIVORCED: "Divorced",
      WIDOWED: "Widowed",
      SEPARATED: "Separated",
    },
  }),
  methods: {
    formatCurrency: function(income) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(income)
    },
    checkForData: function(data) {
      if (data) {
        return data
      }
      else {
        return 'No data has been provided.'
      }
    }
  },
  computed: {
    dataRowContainer() {
      return ['grid', 'grid-cols-4', 'gap-4', 'p-2', 'flex', 'items-center']
    },
    dataRowTitle() {
      return ['col-span-1', 'leading-5', 'text-sm', 'text-gray-500', 'font-medium']
    },
    dataRowContent() {
      return ['col-span-2', 'leading-5', 'text-sm', 'text-gray-900', 'font-medium']
    },
    dataRowBtnContainer() {
      return ['col-span-1', 'flex', 'justify-end', 'py-2']
    },
    inputFieldActive() {
      return ['form-input', 'block', 'w-full', 'text-sm', 'leading-5']
    },
    inputFieldInactive() {
      return ['bg-white', 'block', 'w-full', 'text-sm', 'leading-5']
    },
    selectFieldActive() {
      return ['cursor-default', 'relative', 'w-full', 'rounded-md', 'border', 'border-gray-300', 'bg-white', 'pl-3', 'pr-10', 'py-2', 'text-left', 'focus:outline-none', 'focus:shadow-outline-blue', 'focus:border-blue-300', 'transition', 'ease-in-out', 'duration-150', 'sm:text-sm', 'sm:leading-5']
    },
    editSaveBtn() {
      return ['shadow-sm', 'rounded', 'border', 'border-gray-300', 'bg-white', 'px-2', 'py-1', 'inline-flex', 'items-center', 'text-xs', 'leading-3', 'font-medium', 'text-gray-700', 'hover:text-gray-500', 'focus:outline-none', 'active:bg-gray-100', 'active:text-gray-700', 'transition', 'ease-in-out', 'duration-150']
    },
    cancelBtn() {
      return ['px-2', 'py-1', 'inline-flex', 'items-center', 'text-xs', 'leading-3', 'font-medium', 'text-red-600', 'hover:underline', 'focus:outline-none', 'active:text-red-800', 'transition', 'ease-in-out', 'duration-150']
    },
  },
  methods: {
    cancelEdit: function() {
      this.isEditEnabled = false;
    },
    enableEdit: function() {
      this.isEditEnabled = true;
    },
    save: function() {
      this.$apollo.mutate({
        mutation: updatePersonMutation,
        variables: {
          input: this.buildInput(),
        }
      }).then((response) => {
        const err = response.data.errors ??
                    response.data?.updatePerson?.errors;
        if (err.length) {
          console.error(err);
          return;
        }

        this.isEditEnabled = false;
        this.showSuccessNotification = true;
        setTimeout(() => this.showSuccessNotification = false, 3000);
      })
    },
    buildInput: function() {
      return {
        id: this.person.id,
        person: {
          // ethnicities: this.person.ethnicities,
          gender: this.person.gender,
          maritalStatus: this.person.maritalStatus,
          monthlyIncome: this.person.monthlyIncome,
        }
      };
    },
  },
}
</script>

