<template>
  <div>
    <h2 class="text-lg leading-6 font-medium text-gray-900">Driver's Schedule</h2>
    <div class="px-2 py-3 border border-gray-300">
      <div
        v-for="stop in schedule.stops"
        v-bind:key="stop.id"
        class="flex flex-row border-t last:border-b border-gray-300"
      >
        <div
          class="flex justify-center items-center border-r-2 rounded-l-sm px-2 mr-2"
          :class="statusBackground(stop)"
          :title="statusTitle(stop)"
        >
          <i :class="statusClass(stop)" class="fa-xl fa-fw"></i>
        </div>
        <div class="flex-grow items-center justify-start grid grid-rows grid-cols-9 grid-flow-col gap-2 py-2">

          <div class="col-start-1 col-span-1 truncate">
            <span class="text-xs font-semibold font-mono leading-4 text-gray-700">
              {{ formatTimestamp(stop) }}
            </span>
          </div>

          <div class="col-start-2 col-span-1 truncate">
            <span :title="formatStopKind(stop.kind)" class="text-xs font-semibold capitalize text-gray-700">
              {{ formatStopKind(stop.kind) }}
            </span>
          </div>

          <div class="col-start-3 col-span-2 truncate">
            <span :title="stop.client.listName" class="text-xs font-medium text-gray-900">
              {{ stop.client.listName }}
            </span>
          </div>

          <div class="col-start-5 col-span-3 truncate">
            <span :title="stop.address" class="text-xs font-medium text-gray-900">
              {{ stop.address }}
            </span>
          </div>

          <div class="col-start-8 col-span-2 flex flex-wrap items-start justify-end">
            <span
            v-for="program in stop.leg.programs"
            v-bind:key="program.id"
            :title="program.name"
            class="inline-flex items-center px-2.5 py-0.5 rounded-sm text-xs font-medium leading-4 bg-gray-200 text-gray-800 mt-2 first:mt-0"
             >
              {{ program.shortName }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { format, parseISO } from 'date-fns';
  export default {
    props: {
      schedule: {
        type: Object,
        required: true
      }
    },
    methods: {
      formatTimestamp: function(stop) {
        if (stop.performAtUnspecified) return "CALL";
        return format(parseISO(stop.performAt), 'HH:mm');
      },
      formatStopKind: function(stopKind) {
        return stopKind.toLowerCase()
      },
      statusClass(stop) {
        switch (stop.result) {
          case "NO_DATA": return "fas fa-question text-gray-600";
          case "ACCURATE": return "fas fa-check-square text-blue-600";
          case "INACCURATE": return "far fa-times text-red-600";
          case "SKIPPED": return "fas fa-minus text-gray-600";
          case "DATA_ERROR": return "fas fa-exclamation text-white";
          default: return "fas fa-minus text-gray-600"
        }
      },
      statusBackground(stop) {
        switch (stop.result) {
          case "NO_DATA": return "border-gray-300 bg-gray-100";
          case "ACCURATE": return "border-blue-300 bg-blue-100";
          case "INACCURATE": return "border-red-300 bg-red-100";
          case "SKIPPED": return "border-gray-300 bg-gray-100";
          case "DATA_ERROR": return "border-gray-900 bg-gray-700";
          default: return "border-gray-300 bg-gray-100"
        }
      },
      statusTitle(stop) {
        switch (stop.result) {
          case "NO_DATA": return "No data is available for this stop.";
          case "ACCURATE": return "Driver performed this stop as assigned.";
          case "INACCURATE": return "Driver did not perfom this stop as assigned.";
          case "SKIPPED": return "This stop was skipped because the client was a no show.";
          case "DATA_ERROR": return "We were not able to verify this stop.";
          default: return ""
        }
      }
    },
  }
</script>