<template>
  <div class="max-w-6xl mx-auto mt-32 px-4 sm:px-6 lg:px-8">
    <div class="max-w-3xl mx-auto">
      <div class="flex justify-around">
        <i class="fal fa-cloud-showers-heavy fa-5x text-gray-500"></i>
        <i class="fal fa-cloud-showers-heavy fa-6x text-gray-700"></i>
        <i class="fal fa-cloud-showers-heavy fa-5x text-gray-500"></i>
      </div>
      <div class="flex justify-center mt-10">
        <span class="text-4xl">It's Raining Data!</span>
      </div>
      <div class="flex justify-center mt-12">
        <i class="fad fa-spinner fa-pulse fa-5x text-gray-800"></i>
      </div>
      <div class="flex justify-center mt-12">
        <span class="text-3xl">And We're Sending It To You...</span>
      </div>
      <div class="flex justify-center mt-10">
        <i class="fad fa-computer-classic fa-8x text-blue-700"></i>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      message: {
        type: String,
        required: false
      }
    },
  }
</script>