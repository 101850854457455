<template>
  <div class="divide-y divide-gray-300">

    <div :class="dataRowBtnContainer">
      <button
        @click="enableEdit()"
        v-show="!isEditEnabled"
        type="button"
        :class="editSaveBtn">
        Edit Contact Information
      </button>
      <button
        @click="cancelEdit()"
        v-show="isEditEnabled"
        type="button"
        :class="cancelBtn"
        class="mr-3"
      >
        Cancel
      </button>
      <button
        @click="save()"
        v-show="isEditEnabled"
        type="button"
        :class="editSaveBtn">
        Save
      </button>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Email</div>
      <div :class="dataRowContent">
        {{ person.email }}
      </div>
    </div>

<!-- TODO: Consider phone type/kind and number -->

    <!-- <div :class="dataRowContainer">
      <div :class="dataRowTitle">Primary Phone</div>
      <div :class="dataRowContent">
        <span v-if="person.primaryPhone">{{ person.primaryPhone.kind }} - {{ person.primaryPhone.number }}</span>
        <span v-else>No primary phone number provided.</span>
        <input
          type="text"
          :disabled="!isEditEnabled"
          :class="isEditEnabled? inputFieldActive : inputFieldInactive"
          v-model.trim="person.primaryPhone.number" />
      </div>
    </div> -->

    <!-- <div :class="dataRowContainer">
      <div :class="dataRowTitle">Secondary Phone</div>
      <div :class="dataRowContent">
        <span v-if="person.primaryPhone">{{ person.secondaryPhone.kind }} - {{ person.secondaryPhone.number }}</span>
        <span v-else>No secondary phone number provided.</span>
        <input
          type="text"
          :disabled="!isEditEnabled"
          :class="isEditEnabled? inputFieldActive : inputFieldInactive"
          v-model.trim="person.secondaryPhone.number" />
      </div>
    </div> -->

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Physical Address</div>
      <div :class="dataRowContent">
        <input
          @input="onPhysicalAddressInput()"
          type="text"
          :disabled="!isEditEnabled"
          :class="isEditEnabled? inputFieldActive : inputFieldInactive"
          v-model.trim="person.physicalAddress" />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Physical Address Lat/Long</div>
      <div :class="dataRowContent">
        <span v-if="physicalAddressChanged">Address has been updated. Please refresh to see the new GPS coordinates.</span>
        <span v-else>({{ (person.physicalAddressLat || 0).toFixed(5) }}, {{ (person.physicalAddressLong || 0).toFixed(5) }})</span>
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Directions To Home <i class="fas fa-question-square" title="These directions ARE sent to the driver!"></i></div>
      <div class="relative col-span-2 rounded-md shadow-sm">
        <textarea
        v-model.trim="person.physicalAddressDirections"
        :disabled="!isEditEnabled"
        maxlength="150"
        rows="2"
        class="form-textarea block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5">
        </textarea>
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Township</div>
      <div :class="dataRowContent">
        <input
          type="text"
          :class="isEditEnabled? inputFieldActive : inputFieldInactive"
          :disabled="!isEditEnabled"
          v-model.trim="person.township" />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Mailing Address</div>
      <div :class="dataRowContent">
        <input
          type="text"
          :disabled="!isEditEnabled"
          :class="isEditEnabled? inputFieldActive : inputFieldInactive"
          v-model.trim="person.mailingAddress" />
      </div>
    </div>

    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Emergency Contact</div>
      <div :class="dataRowContent">
        <input
          type="text"
          :disabled="!isEditEnabled"
          :class="isEditEnabled? inputFieldActive : inputFieldInactive"
          v-model.trim="person.emergencyContact" />
      </div>
    </div>

    <template v-if="showSuccessNotification">
      <transition
        enter-active-class="transition ease-out duration-400 transform"
        enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <notification-success>User data has been updated</notification-success>
      </transition>
    </template>

  </div>
</template>

<script>
import notificationSuccess from './../../../shared-components/notifications/notification-success';
import gql from 'graphql-tag';

const updatePersonMutation = gql`
mutation updatePerson($input: UpdatePersonInput!) {
  updatePerson(input: $input) {
    person {
      id
      physicalAddress
      physicalAddressLat
      physicalAddressLong
      physicalAddressDirections
      township
      mailingAddress
      emergencyContact
    }
    errors {
      message
      path
    }
  }
}
`;

export default {
  components: { notificationSuccess },
  props: {
    person: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    isEditEnabled: false,
    showSuccessNotification: false,
    physicalAddressChanging: false,
    physicalAddressChanged: false,
  }),
  computed: {
    dataRowContainer() {
      return ['grid', 'grid-cols-4', 'gap-4', 'p-2', 'flex', 'items-center']
    },
    dataRowTitle() {
      return ['col-span-1', 'leading-5', 'text-sm', 'text-gray-500', 'font-medium']
    },
    dataRowContent() {
      return ['col-span-2', 'leading-5', 'text-sm', 'text-gray-900', 'font-medium']
    },
    dataRowBtnContainer() {
      return ['col-span-1', 'flex', 'justify-end', 'py-2']
    },
    inputFieldActive() {
      return ['form-input', 'block', 'w-full', 'text-sm', 'leading-5']
    },
    inputFieldInactive() {
      return ['bg-white', 'block', 'w-full', 'text-sm', 'leading-5']
    },
    editSaveBtn() {
      return ['shadow-sm', 'rounded', 'border', 'border-gray-300', 'bg-white', 'px-2', 'py-1', 'inline-flex', 'items-center', 'text-xs', 'leading-3', 'font-medium', 'text-gray-700', 'hover:text-gray-500', 'focus:outline-none', 'active:bg-gray-100', 'active:text-gray-700', 'transition', 'ease-in-out', 'duration-150']
    },
    cancelBtn() {
      return ['px-2', 'py-1', 'inline-flex', 'items-center', 'text-xs', 'leading-3', 'font-medium', 'text-red-600', 'hover:underline', 'focus:outline-none', 'active:text-red-800', 'transition', 'ease-in-out', 'duration-150']
    },
  },
  methods: {
    cancelEdit: function() {
      this.isEditEnabled = false;
    },
    enableEdit: function() {
      this.isEditEnabled = true;
    },
    save: function() {
      if (this.physicalAddressChanging) {
        this.physicalAddressChanged = true;
      }
      this.$apollo.mutate({
        mutation: updatePersonMutation,
        variables: {
          input: this.buildInput(),
        }
      }).then((response) => {
        const err = response.data.errors ??
                    response.data?.updatePerson?.errors;
        if (err.length) {
          console.error(err);
          return;
        }

        this.isEditEnabled = false;
        this.showSuccessNotification = true;
        setTimeout(() => this.showSuccessNotification = false, 3000);
      })
    },
    buildInput: function() {
      return {
        id: this.person.id,
        person: {
          physicalAddress: this.person.physicalAddress,
          physicalAddressDirections: this.person.physicalAddressDirections,
          township: this.person.township,
          mailingAddress: this.person.mailingAddress,
          emergencyContact: this.person.emergencyContact,
        }
      };
    },
    onPhysicalAddressInput: function() {
      this.physicalAddressChanging = true;
    }
  },
}
</script>