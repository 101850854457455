<template>
  <div class="relative">
    <div id="mapid" ref="mapElement" class="z-0 relative h-144"></div>
    <div class="bg-gray-700 text-gray-100 absolute bottom-0 right-0 z-0 px-3 py-1">
      Breadcrumbs
      <input
      type="checkbox"
      id="checkbox"
      >
    </div>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';

  export default {
    props: {
      mapCenter: {
        type: Array,
        required: true
      },
      activities: {
        type: Array,
        required: false
      },
      breadcrumbs: {
        type: Object,
        required: false
      },
    },
		data() {
			return {
        mymap: null,
        tileLayer: null,
        layers: [],
        checked: false,
        breadcrumbList: []
      }
    },
    mounted() {
      this.initMap();
      this.initActivites();
    },
    watch: {
    	mapCenter: function(newCenter, oldCenter) {
        this.mymap.flyTo(new L.LatLng(newCenter[0], newCenter[1]),14);
      },
      breadcrumbs: function(newValue, oldValue) {
        deep: true;
        this.extractBreadcrumbs(newValue);
        this.initBreadcrumbs();
      },
    },
    methods: {
      initMap() {
        this.mymap = L.map(this.$refs['mapElement']).setView(this.mapCenter, 9);
      	this.tileLayer = L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
          maxZoom: 18,
        });
        this.tileLayer.addTo(this.mymap);
      },
      initActivites() {
        const activityGroup = L.featureGroup();
        const activityFeatures = this.activities.forEach(activity => {
          if (activity.latitude == null || activity.longitude == null) {
            activity.latitude = 0.0;
            activity.longitude = 0.0;
          };
          let latlng = L.latLng(activity.latitude, activity.longitude);
          let preciseLocation = L.circle(latlng, {
            color: '#253046',
          	fill: false,
          	radius: 30 //in meters
            });
          let approxLocation = L.circle(latlng, {
            color: '#d87a00',
          	fillColor: '#d87a00',
          	fillOpacity: 0.05,
            radius: 1000, //in meters
            })
            .on("click", this.onClick)
            .bindPopup(this.popupActivityContent(activity));
            activityGroup.addLayer(preciseLocation)
            activityGroup.addLayer(approxLocation)
        });
        activityGroup.addTo(this.mymap);
        this.layers.push(activityGroup);
      },
      initBreadcrumbs() {
        const breadcrumbGroup = L.featureGroup();
        const breadcrumbPopup = L.popup();
        const breadcrumbFeatures = this.breadcrumbList.forEach(breadcrumb => {
          if (breadcrumb.latitude == null || breadcrumb.longitude == null) {
            breadcrumb.latitude = 0.0;
            breadcrumb.longitude = 0.0;
          };
          let latlng = L.latLng(breadcrumb.latitude, breadcrumb.longitude);
          let breadcrumbLocation = L.circle(latlng, {
            color: 'red',
          	fill: false,
            radius: 15, //in meters
            })
            .on("click", this.onClick)
            .bindPopup(this.popupBreadcrumbContent(breadcrumb))
          breadcrumbGroup.addLayer(breadcrumbLocation);
        });
        breadcrumbGroup.addTo(this.mymap);
        this.layers.push(breadcrumbGroup);
      },
      onClick() {
        return; //handle click event?
      },
      popupActivityContent(activity) {
        // TODO: this used clientName field, which was only useful for manual entries,
        // and has now been removed entirely.
        return '<strong>' + activity.kind + '</strong>'+
         '<br />Timestamp: ' + this.formatTimestamp(activity.timestamp);
      },
      popupBreadcrumbContent(breadcrumb) {
        return 'Location Update: ' + this.formatTimestamp(breadcrumb.timestamp) +
        '<br />' + breadcrumb.latitude.toPrecision(7) + ', ' + breadcrumb.longitude.toPrecision(7) ;
      },
      formatTimestamp: function(activityTimestamp) {
        return format(parseISO(activityTimestamp), 'HH:mm');
      },
      extractBreadcrumbs: function(b) {
        const crumbs = b
        crumbs.runs.forEach((run) => {
        this.breadcrumbList.push(...run.breadcrumbs)
        });
      },
    },
  }
</script>
