<template>
  <div>
    <div class="h-full overflow-x-auto border-2 border-gray-300 rounded-t">
      <div
        v-for="(group, index) in groups"
        v-bind:key="group.id"
        class="flex flex-col"
      >
        <div v-if="index === 0" class="flex bg-gray-100 px-4 py-2">
          <i class="fal fa-map-marker-alt fa-lg text-gray-500"></i>
          <span class="text-sm text-gray-500 px-4">{{ group.startAddress }}</span>
        </div>

        <div class="flex flex-col px-4 py-3">
          <div class="flex justify-between items-center pb-1">
            <div>
              <span class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-1 bg-green-100 text-green-800 mr-2">
                {{ formatCurrency(group.amountDisplay) }}
              </span>
              <span class="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-1 bg-gray-100 text-gray-800 mr-2">
                {{ group.distanceDisplay }} miles
              </span>
            </div>
            <div class="relative inline-block text-left">
              <span class="relative z-0 inline-flex shadow-sm rounded-md">
                <button
                  type="button"
                  @click="updateGroupStrategy(group.id, 'DRIVING')"
                  :title="isOdometerStrategyInUse ? 'Default schedule route reimbursement is unavailable when using the Start/End Odometer Strategy' : 'Default schedule route reimbursement'"
                  :class="isOdometerStrategyInUse ? ['cursor-not-allowed','text-gray-500','opacity-50'] : ['hover:bg-blue-50','text-gray-700','focus:z-0','focus:ring-1','focus:ring-blue-500','focus:border-blue-500']"
                  class="relative inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium focus:outline-none"
                >
                  <i class="fal fa-route fa-lg fa-fw"></i>
                </button>
                <!-- breadcrumb strategy is not in scope at the moment -->
                <!-- <button
                  type="button"
                  :title="isOdometerStrategyInUse ? 'Breadcrumb reimbursement is unavailable when using the Start/End Odometer Strategy' : 'Breadcrumb reimbursement using GPS data from the driver\'s device'"
                  :class="isOdometerStrategyInUse ? ['cursor-not-allowed','text-gray-500','opacity-50',] : ['hover:bg-blue-50','text-gray-700','focus:z-0','focus:ring-1','focus:ring-blue-500','focus:border-blue-500']"
                  class="relative inline-flex items-center px-2 py-1 border border-gray-300 bg-white text-sm font-medium focus:outline-none"
                >
                  <i class="fal fa-map-pin fa-lg fa-fw"></i>
                </button> -->
                <button
                  type="button"
                  @click="showModalManualReimbursementEdges(group.reimbursementEdges)"
                  class="-ml-px relative inline-flex items-center px-2 py-1 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-blue-50 focus:z-0 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  title="Manually edit reimbursement amount"
                >
                  <i class="fal fa-edit fa-lg fa-fw"></i>
                </button>
              </span>
            </div>
          </div>
          <div>
            <div  class="pl-6 pr-2 border-b first:border-t border-gray-200">
              <div
                v-for="edge in group.reimbursementEdges"
                v-bind:key="edge.id"
                class="grid grid-flow-row-dense grid-cols-7 grid-rows-1 items-center"
              >
                <div :title="edge.deadhead? 'deadhead miles attributed to this client' : 'client name'" class="col-start-1 col-span-3 text-xs text-gray-900">
                  {{ edge.client.displayName }}
                  <span v-if="edge.deadhead" class="rounded bg-red-200 text-red-800 ml-2 px-2">deadhead</span>
                </div>
                <div title="program abbreviation" class="col-start-4 col-span-1 flex items-center text-xs text-gray-900">{{ edge.program.shortName }}</div>
                <div title="program reimbursement rate" class="col-start-5 col-span-1 text-xs text-gray-900 font-mono">{{ edge.reimbursementRateDisplay }}</div>
                <div title="miles attributed" class="col-start-6 col-span-1 text-xs text-gray-900 font-mono overflow-hidden">
                  <span>
                    {{ edge.distanceDisplay }}
                  </span>
                </div>
                <div class="col-start-7 col-span-1 py-0.5 text-xs text-right text-gray-900">
                  <!-- TODO: at some point, this broke. If you put a console.log in the method(s) below, it shows that it is being called,
                        but the element is not being updated. Even returning this to a set of v-if elements did not resolve the issue.
                        Apollo is working correct, because if you put an {{ edge.strategy }} literal in this div, it has the correct value. -->
                  <i
                    :class="strategyIconClass(edge)"
                    :title="strategyTitle(edge)"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex bg-gray-100 px-4 py-2">
          <i class="fal fa-map-marker-alt fa-lg text-gray-500"></i>
          <span class="text-sm text-gray-500 px-4">{{ group.endAddress }}</span>
        </div>
      </div>

      <modal-manual-reimbursement-edges
        :isVisible="modalManualReimbursementEdgesVisible"
        :group="modalManualReimbursementEdgesData"
        @closeDialog="closeDialog"
        @confirmDialog="onSaveManualReimbursementAdjustment"
      >
      </modal-manual-reimbursement-edges>

    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import modalManualReimbursementEdges from './modal-manual-reimbursement-edges';

const setEdgeManualDistanceMutation = gql `
mutation setEdgeManualDistance($input: SetEdgeManualDistanceInput!) {
  setEdgeManualDistance(input: $input) {
    reimbursement {
      id
      totalDisplay
      drivingReimbursementDisplay
      reimbursementEdgeGroups {
        id
        amountDisplay
        distanceDisplay
        reimbursementEdges {
          id
          strategy
          distanceDisplay
          amountDisplay
        }
      }
      comments {
        id
        content
        createdAt
        authorName
      }
    }
    errors
  }
}
`;

const updateGroupReimbursementStrategyMutation = gql `
mutation updateGroupReimbursementStrategy($input: UpdateGroupReimbursementStrategyInput!) {
  updateGroupReimbursementStrategy(input: $input) {
    reimbursement {
      id
      totalDisplay
      drivingReimbursementDisplay
      reimbursementEdgeGroups {
        id
        amountDisplay
        distanceDisplay
        reimbursementEdges {
          id
          strategy
          amountDisplay
          distanceDisplay
        }
      }
      comments {
        id
        content
        createdAt
        authorName
      }
    }
    errors
  }
}
`;

export default {
  components: { modalManualReimbursementEdges },
  props: {
    reimbursementId: {
      type: String,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    // The parent component already performs the work of testing the strategy properties
    // of edges nested in groups, so just accept a boolean prop instead of repeating.
    isOdometerStrategyInUse: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      modalManualReimbursementEdgesVisible: false,
      modalManualReimbursementEdgesData: null
    }
  },
  computed: {},
  methods: {
    showModalManualReimbursementEdges(data) {
      this.modalManualReimbursementEdgesData = data;
      this.modalManualReimbursementEdgesVisible = true
    },
    closeDialog: function() {
      this.modalManualReimbursementEdgesData = null;
      this.modalManualReimbursementEdgesVisible = false
    },
    onSaveManualReimbursementAdjustment: function(obj) {
      this.$apollo.mutate({
        mutation: setEdgeManualDistanceMutation,
        variables: {
          input: {
            reimbursementId: this.reimbursementId,
            edgeData: obj.edgeData,
            comment: obj.message,
          },
        }
      }).then(response => {
        const err = response.errors || response.data.setEdgeManualDistance.errors
        if (err.length) {
          console.error(err)
          this.failure(err)
          return
        }

        this.success()
      }).catch(error => {
        console.error(error)
        this.failure(error)
      })
    },
    updateGroupStrategy(groupId, strategy) {
      this.$apollo.mutate({
        mutation: updateGroupReimbursementStrategyMutation,
        variables: {
          input: {
            reimbursementId: this.reimbursementId,
            groupId: groupId,
            strategy: strategy,
          },
        }
      }).then(response => {
        const err = response.errors || response.data.updateGroupReimbursementStrategy.errors
        if (err.length) {
          console.error(err)
          this.failure(err)
          return
        }

        this.success()
      }).catch(error => {
        console.error(error)
        this.failure(error)
      })
    },
    success: function() {
      this.$emit("onSuccess");
    },
    failure: function(errArray) {
      this.$emit("onFailure", errArray);
    },
    strategyIconClass: function(edge) {
      switch(edge.strategy) {
        case 'DRIVING':
          return 'fal fa-route fa-lg fa-fw'
          break;
        case 'ODOMETER':
          return 'fal fa-tachometer-slowest fa-sm fa-fw'
          break;
        case 'BREADCRUMB':
          return 'fal fa-map-pin fa-sm fa-fw'
          break;
        case 'MANUAL':
          return 'fal fa-edit fa-sm fa-fw'
          break;
        default:
          console.warn(`unknown strategy ${edge.strategy}`)
          return ''
      }
    },
    strategyTitle: function(edge) {
      switch(edge.strategy) {
        case 'DRIVING':
          return "Calculated using scheduled route data"
          break;
        case 'ODOMETER':
          return "Calculated using driver's provided start/end odometer data"
          break;
        case 'BREADCRUMB':
          return "Calculated using driver's breadcrumb data"
          break;
        case 'MANUAL':
          return "Reimbursement manually set by staff"
          break;
        default:
          console.warn(`unknown strategy ${edge.strategy}`)
          return ''
      }
    },
    formatCurrency: function(amount) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(amount)
    },
  }
}
</script>
