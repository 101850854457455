<template>
  <div class="flex-auto overflow-y-auto h-full bg-gray-100">
    <div class="mt-8 mx-8">

      <template v-if="$apollo.loading">
        <apollo-loading></apollo-loading>
      </template>

      <template v-if=person>
        <div class="flex h-auto">
          <!-- User Info Side -->
          <div class="flex-none max-w-sm w-full pl-8 pr-16">
            <div class="pb-4 flex-col items-center flex-shrink-0 bg-white border-b-2 border-gray-400 rounded-md">
              <img class="h-full w-full pb-4" src="https://tkt-adama-public.s3.amazonaws.com/generic_avatar_square.png" />
              <span class="p-4">No status set.</span>
            </div>

            <div class="mt-6">
              <h1 class="text-3xl font-semibold text-gray-900">{{ person.displayName }}</h1>
              <h2 class="text-xl font-light text-gray-600 -mt-1">{{ designatedRole }}</h2>
            </div>
            <div class="mt-4 pb-4 border-b border-gray-500">
              <p>This bio has not been filled out. It is a perfect place to list public info about yourself or on behalf of a user.</p>
            </div>

            <div class="flex flex-col mt-3 pb-4 border-b border-gray-500">
              <address class="flex flex-col justify-start leading-5 tracking-wide text-gray-700 not-italic">
                <span class="user-profile-address-a-tag">
                  <i class="fal fa-phone-rotary fa-fw user-profile-address-svg"></i>
                  <a
                    v-if="person.primaryPhone"
                    :title="person.primaryPhone.kind"
                    :href="`tel:${person.primaryPhone.number}`"
                  >
                    {{ person.primaryPhone.number }}
                  </a>
                  <span v-else>No primary number provided.</span>
                </span>
                <span class="mt-2 user-profile-address-a-tag">
                  <i class="fal fa-envelope fa-fw user-profile-address-svg"></i>
                  <a
                    v-if="person.email"
                    :href="`mailto:${person.email}`"
                  >
                    {{ person.email }}
                  </a>
                  <span v-else>No email provided.</span>
                </span>
                <span class="mt-2 user-profile-address-a-tag">
                  <i class="fal fa-house fa-fw user-profile-address-svg"></i>
                  <a
                    v-if="person.physicalAddress"
                    :href="`http://maps.google.com/?q=${person.physicalAddress}`" target="_blank" rel="noreferrer noopener"
                  >
                    {{ person.physicalAddress }}
                  </a>
                  <span v-else>No home address provided.</span>
                </span>
              </address>
            </div>

            <!-- TODO: add organization groups, commented out placeholder for now. -->
            <!-- <div class="mt-3 pb-4 border-b border-gray-500">
              <h3 class="text-lg font-semibold text-gray-700">Groups</h3>
              <div v-if="groups" class="flex mt-2">
                <span class="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium leading-5 bg-green-200 text-green-800">
                  Thoughts for Thuesday
                </span>
              </div>
            </div> -->

            <div v-if="person.clientPrograms.length > 0" class="mt-3 pb-4 border-b border-gray-500">
              <h3 class="text-lg font-semibold text-gray-700">Programs</h3>
              <div v-for="prgObj in person.clientPrograms" :key="prgObj.id" class="flex mt-2">
                <span
                  :title="`${prgObj.program.name} - ${prgObj.program.kind}`"
                  class="inline-flex items-center px-2 py-1 rounded-md text-sm font-medium leading-5 bg-indigo-200 text-indigo-800"
                >
                  <svg class="-ml-1 mr-2 h-2 w-2 text-indigo-500" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="3" />
                  </svg>
                  {{prgObj.program.shortName}}
                </span>
              </div>
            </div>
          </div>

          <!-- Nav right side panel -->
          <div class="flex-1 bg-white overflow-hidden shadow rounded-lg mb-8">
            <div class="px-4 pb-5">
              <div>
                <div class="hidden sm:block">
                  <div class="border-b border-gray-200">
                    <nav class="flex text-lg font-medium leading-5 -mb-px">
                      <a
                        @click="selectTab('Details')"
                        :class="tab === 'Details' ? ['border-blue-600', 'text-blue-600', 'focus:outline-none', 'focus:text-blue-800', 'focus:border-blue-700'] : ['border-transparent', 'text-gray-500']"
                        class="group cursor-pointer inline-flex items-center border-b-2 ml-8 py-4 px-1"
                      >
                        <span>Details</span>
                      </a>
                      <a
                        @click="selectTab('Vehicles')"
                        :class="tab === 'Vehicles' ? ['border-blue-600', 'text-blue-600', 'focus:outline-none', 'focus:text-blue-800', 'focus:border-blue-700'] : ['border-transparent', 'text-gray-500']"
                        class="group cursor-pointer inline-flex items-center border-b-2 ml-8 py-4 px-1"
                      >
                        <span>Vehicles</span>
                      </a>
                      <a
                        @click="selectTab('Settings')"
                        :class="tab === 'Settings' ? ['border-blue-600', 'text-blue-600', 'focus:outline-none', 'focus:text-blue-800', 'focus:border-blue-700'] : ['border-transparent', 'text-gray-500']"
                        class="group cursor-pointer inline-flex items-center border-b-2 ml-8 py-4 px-1"
                      >
                        <span>Settings</span>
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="overflow-y-auto">
              <div v-if="tab === 'Details'">
                <user-details :person="person"></user-details>
              </div>
              <div v-if="tab === 'Vehicles'">
                <vehicles :driverId="person.id" :vehicles="person.vehicles"></vehicles>
              </div>
              <div v-if="tab === 'Settings'">
                <settings :userId="person.id"></settings>
              </div>
            </div>
          </div>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
  import apolloLoading from './../../shared-components/apollo-loading/apollo-loading';
  import userDetails from './components/user-details';
  import gql from 'graphql-tag';
  import settings from './components/settings';
  import vehicles from './components/vehicles';

  const personQuery = gql`
  query person($id:ID!) {
    userProfile:person(id: $id) {
      id
      displayName
      firstName
      middleName
      lastName
      dateOfBirth
      ssnLastFour
      email
      phones {
        id
        archived
        kind
        number
        primary
      }
      primaryPhone {
        number
        kind
      }
      township
      physicalAddress
      physicalAddressDirections
      mailingAddress
      physicalAddress
      physicalAddressDirections
      physicalAddressLat
      physicalAddressLong
      emergencyContact
      ethnicities
      gender
      maritalStatus
      monthlyIncome
      isBariatric
      isFrailOrDisabled
      isVeteran
      livesAlone
      needsDoorToDoorAssistance
      needsVehicleAssistance
      needsWheelchairVehicle
      usesWheelchair
      usesWalker
      usesOxygenTank
      clientPrograms {
        program {
          id
          name
          shortName
          kind
        }
      }
      medicaidNumber
      medicaidCounty
      vehicles {
        id
        year
        make
        model
        licensePlate
        status
        isWheelchairVehicle
      }
      internalNotes {
        content
        authorName
        createdAt
      }
    }
  }`;

  export default {
    components: { apolloLoading, userDetails, settings, vehicles },
    props: {
      id: {
        type: String,
        required: true
      }
    },
    data: () => ({
      // person: null,
      tab: 'Details',
      groups: true,
      designatedRole: "Most Excellent User", //TODO: create this data? User really should be [staff, admin, driver, volunteer]
    }),
    computed: {
      programsForClient: function() {
        const clPrgms = this.person.clientPrograms ?? []
        return this.person.clientPrograms.flat();
      }
    },
    apollo: {
      person: {
        query: personQuery,
        variables() {
          return {
            id: this.id,
          }
        },
        update: data => data.userProfile,
      }
    },
    methods: {
      selectTab: function(tab) {
        this.tab = tab;
      }
    }

  }
</script>
