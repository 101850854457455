<template>
  <div>
    <div>
      <div class="flex justify-between">
        <!-- TODO: add a badge here after reimbursement has been set processed to show this reimbursement is 'frozen' and cannot be edited  -->
        <h3 class="font-medium text-base text-gray-800 py-1">Reimbursement</h3>
        <h3>{{ reimbursement.effectiveDate }}</h3>
      </div>
      <template v-if="reimbursement.reimbursementEdgeGroups.length">
        <h4 class="font-medium text-sm text-gray-800 mt-2">Main Reimbursement Strategy</h4>
        <fieldset>
          <legend class="sr-only">
            Reimbursement Strategy
          </legend>
          <div class="flex bg-white rounded-md -space-x-px">
            <!--
              TODO: the cursor - either not-allowed or pointer - should be set on the outmost div so that we can set once and have the children inherit

              From reading the Vue docs I was able to find how to combine multiple clauses into one binding: https://vuejs.org/v2/guide/class-and-style.html
            -->
            <div
              @click.prevent="selectScheduleRouteStrategy()"
              :class="[isStrategyScheduledRoute ? ['bg-blue-50', 'border-blue-200', 'z-0'] : 'border-gray-200', { ['cursor-not-allowed'] : isLocked }]"
              class="relative border rounded-l-md px-4 py-2 flex w-full"
              title="Use mileage data provided and calculated by a service such as Google Directions API to determine driver reimbursement"
            >
              <div class="flex items-center h-5">
                <input
                  type="radio"
                  v-model="reimbursementStrategy"
                  value="schedule-reimbursement"
                  id="schedule-reimbursement"
                  name="reimbursement_strategy"
                  :disabled="isLocked"
                  class="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out cursor-pointer"
                >
              </div>
              <label for="schedule-reimbursement" class="ml-3 flex-auto flex-col cursor-pointer">
                <div class="flex justify-between">
                  <span
                    :class="isStrategyScheduledRoute ? 'text-blue-900' : 'text-gray-900'"
                    class="block text-sm leading-5 font-medium"
                  >
                    Scheduled Route
                  </span>
                  <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium leading-4 bg-gray-100 border border-gray-300 text-gray-800">
                    Default
                  </span>
                </div>
              </label>
            </div>
            <div
              @click.prevent="selectOdometerStrategy()"
              :class="[isStrategyOdometer ? ['bg-blue-50', 'border-blue-200', 'z-0'] : 'border-gray-200', { ['cursor-not-allowed'] : isOdometerStrategyUnavailable }]"
              class="relative border rounded-r-md px-4 py-2 flex w-full"
              title="Use start and end odometer data, if optionally provided by driver, to calculate reimbursement amounts"
            >
              <div class="flex items-center h-5">
                <input
                  type="radio"
                  v-model="reimbursementStrategy"
                  value="odometer-reimbursement"
                  id="odometer-reimbursement"
                  name="reimbursement_strategy"
                  :disabled="isOdometerStrategyUnavailable"
                  class="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out cursor-pointer"
                  :class="{ ['cursor-not-allowed'] : isOdometerStrategyUnavailable }"
                >
              </div>
              <label for="odometer-reimbursement" class="ml-3 flex flex-col cursor-pointer">
                <span
                  :class="isStrategyOdometer ? 'text-blue-900' : 'text-gray-900'"
                  class="block text-sm leading-5 font-medium"
                >
                  Start/End Odometer
                </span>
              </label>
            </div>

          </div>
        </fieldset>
      </template>
      <template v-else> <!-- TODO: style me! -->
        This reimbursement does not cover mileage driven.
      </template>
    </div>

    <div v-if="reimbursement.reimbursementEdgeGroups.length" class="mt-6">
      <h4 class="font-medium text-sm text-gray-800">Mileage Reimbursement</h4>
      <reimbursement-run-redo
        :reimbursementId="reimbursement.id"
        :groups="reimbursement.reimbursementEdgeGroups"
        :isOdometerStrategyInUse="this.isStrategyOdometer"
        @onSuccess="displaySuccessNotification"
        @onFailure="displayFailureNotification"
        class="h-160"
      >
      </reimbursement-run-redo>
    </div>

    <div class="mt-6">
      <h4 class="font-medium text-sm text-gray-800">Total Reimbursement</h4>
      <div class="py-1 flex justify-between text-xs border-gray-300 border-t border-b">
        <div class="text-gray-700">Mileage Reimbursement</div>
        <div class="font-mono text-gray-700">{{ formatToCurrency(reimbursement.drivingReimbursementDisplay) }}</div>
      </div>
      <div class="py-1 flex justify-between text-xs border-gray-300 border-b">
        <div class="text-gray-700">Expense Reimbursement</div>
        <div class="font-mono text-gray-700">{{ formatToCurrency(reimbursement.expenseReimbursementDisplay) }}</div>
      </div>
      <div class="py-1 flex justify-between text-xs border-gray-300 border-b">
        <div class="text-gray-900">Total</div>
        <div class="font-mono text-gray-800">{{ formatToCurrency(reimbursement.totalDisplay) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import reimbursementRunRedo from './reimbursement-run-redo.vue';

const useOdometerStrategyMutation = gql`
mutation useOdometerStrategy($input: UseOdometerStrategyInput!) {
  useOdometerStrategy(input: $input) {
    reimbursement {
      id
      totalDisplay
      drivingReimbursementDisplay
      reimbursementEdgeGroups {
        id
        amountDisplay
        distanceDisplay
        reimbursementEdges {
          id
          strategy
          distanceDisplay
          drivingDistanceDisplay
          odometerDistanceDisplay
          amountDisplay
        }
      }
      comments {
        id
        content
        createdAt
        authorName
      }
    }
    errors
  }
}
`;

const useScheduledStrategyMutation = gql`
mutation useScheduledStrategy($input: UseScheduledStrategyInput!) {
  useScheduledStrategy(input: $input) {
    reimbursement {
      id
      totalDisplay
      drivingReimbursementDisplay
      reimbursementEdgeGroups {
        id
        amountDisplay
        distanceDisplay
        reimbursementEdges {
          id
          strategy
          distanceDisplay
          drivingDistanceDisplay
          odometerDistanceDisplay
          amountDisplay
        }
      }
      comments {
        id
        content
        createdAt
        authorName
      }
    }
    errors
  }
}
`;

export default {
  components: { reimbursementRunRedo, },
  props: {
    reimbursement: {
      type: Object,
      required: true,
    },
    odometersAvailable: {
      type: Boolean,
      required: true
    },
  },
  data: () => ({
    reimbursementIdForExpense: '',
    // TODO: modal is broken from move; will need to emit to use shared modal
    // Might not be a large concern, as expense model is changing to be leg/group scoped
    // Not going to fix it for now.
    modalExpenseReimbursementVisible: false,
  }),
  computed: {
    // TODO: in the future, also use a prop for determining if the user has write permission
    isLocked: function() {
      return false; // TODO: this function is now useless after approving was removed
    },
    isOdometerStrategyUnavailable: function() {
      // consider renaming this so it's positive
      return this.isLocked || !this.odometersAvailable
    },
    isStrategyOdometer: function() {
      return this.reimbursement
        .reimbursementEdgeGroups
        .map(g => g.reimbursementEdges)
        .flatMap(e => e)
        .some(s => s.strategy === 'ODOMETER')
    },
    isStrategyScheduledRoute: function() {
      return !this.isStrategyOdometer
    },
    reimbursementStrategy: function() {
      if (this.isStrategyOdometer) return 'odometer-reimbursement'

      return 'schedule-reimbursement'
    },
  },
  methods: {
    // TODO: might remove methods for expense - changing to group/edge scoped
    // TODO: should debounce buttons
    selectScheduleRouteStrategy() {
      if (this.isLocked) return

      this.$apollo.mutate({
        mutation: useScheduledStrategyMutation,
        variables: {
          input: {
            id: this.reimbursement.id,
          }
        }
      }).then(response => {
        const err = response.errors || response.data.useScheduledStrategy.errors
        if (err.length) {
          console.error(err)
          this.displayFailureNotification(err)
          return
        }

        this.displaySuccessNotification();
      }).catch(error => {
        console.error(error)
        this.displayFailureNotification(error)
      })
    },
    selectOdometerStrategy() {
      if (this.isOdometerStrategyUnavailable) return

      this.$apollo.mutate({
        mutation: useOdometerStrategyMutation,
        variables: {
          input: {
            id: this.reimbursement.id,
          }
        }
      }).then((response) => {
        const err = response.errors || response.data.useOdometerStrategy.errors
        if (err.length) {
          console.error(err)
          this.displayFailureNotification(err)
          return
        }

        this.displaySuccessNotification();
      }).catch(error => {
        console.error(error)
        this.displayFailureNotification(error)
      })
    },
    displaySuccessNotification() {
      this.$emit("onSuccess");
    },
    displayFailureNotification(errArray) {
      this.$emit("onFailure", errArray);
    },
    hideModalExpenseReimbursement() {
      this.reimbursementIdForExpense = ''
      this.modalExpenseReimbursementVisible = false
    },
    showModalExpenseReimbursement(id) {
      this.reimbursementIdForExpense = id
      this.modalExpenseReimbursementVisible = true
    },
    onAddExpense(obj) {
      const { reimbursementId, ...expense } = obj
      this.$apollo.mutate({
        mutation: createReimbursementExpenseMutation,
        variables: {
          input: {
            id: reimbursementId,
            reimbursementExpense: expense
          }
        }
      }).then((response) => {
        const err = response.errors || response.data.createReimbursementExpense.errors
        if (err.length) {
          console.error(err)
          this.displayFailureNotification(err);
          return
        }

        this.displaySuccessNotification();
      }).catch(error => {
        console.error(error)
        this.displayFailureNotification(error);
      })
    },
    removeExpense(reimbursementId, expenseId) {
      this.$apollo.mutate({
        mutation: deleteReimbursementMutation,
        variables: {
          input: {
            reimbursementId: reimbursementId,
            reimbursementExpenseId: expenseId,
          }
        }
      }).then((response) => {
        const err = response.errors || response.data.deleteReimbursementExpense.errors
        if (err.length) {
          console.error(err)
          this.displayFailureNotification(err);
          return
        }

        this.displaySuccessNotification();
      }).catch(error => {
        console.error(error)
        this.displayFailureNotification(error);
      })
    },
    formatToCurrency(value) {
      if (typeof value === 'undefined') { return "$0.00"};
      const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
        }).format(value);
        // }).format(value / 1000);
      return formattedValue;
    },
  },
}
</script>
