<template>
  <div class="flex flex-col border border-gray-300">

    <div class="flex flex-col bg-gray-50">
      <div v-if="comments.length" class="overflow-y-auto h-64 px-2 space-y-1">
        <div
        v-for="comment in comments"
        :key="comment.id"
        class="p-2"
        >
          <div class="min-w-0 flex-1">
            <div class="flex">
              <p class="text-sm font-medium text-gray-900">{{ comment.authorName }}</p>
              <p class="text-xs pl-3 mt-0.5 text-gray-500 italic">{{ formatTimestamp(comment.createdAt) }}</p>
            </div>
            <div class="mt-1 text-xs text-gray-700">
              <p class="pl-3">{{ comment.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex flex-col justify-center items-center border-2 border-dashed border-gray-400 bg-gray-100 text-gray-700 h-64 pl-2">
        <i class="fas fa-ghost fa-2x text-gray-500"></i>
        <p class="font-thin px-8 text-center">No staff activity or comments to display</p>
        <p class="font-thin px-8 text-center">You could be the first!</p>
      </div>
    </div>

     <div class="flex flex-col px-4 py-4 border-t border-gray-300">
      <div class="flex justify-between">
        <label for="staff_comment" class="block text-sm font-medium leading-5 text-gray-700">Add a comment</label>
        <span class="text-sm leading-5 text-gray-500">Max. 250 characters</span>
      </div>
      <div class="mt-1 relative rounded-md shadow-sm">
        <textarea
        v-model.trim="newComment"
        id="staff_comment"
        maxlength="250"
        rows="4"
        class="form-textarea block w-full h-28 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
        >
        </textarea>
      </div>
      <div class="flex items-center">
        <span class="text-xs leading-5 text-gray-500">{{ newComment.length }}/250 characters used</span>
        <span v-if="newComment.length > 249" class="text-xs italic leading-5 text-blue-500 pl-3">Concision is key!</span>
        <span v-if="newComment.length > 249" class="pl-2 pt-1">&#128521;</span>
      </div>
      <div class="text-right sm:col-span-2">
        <span class="inline-flex rounded-md shadow-sm">
          <button
          @click="submitComment"
          type="button"
          :class="newComment.length === 0 ? ['opacity-50','cursor-not-allowed'] : ['hover:bg-blue-500']"
          class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Add Awesome Comment
          </button>
        </span>
      </div>
    </div>

  </div>
</template>

<script>
import gql from 'graphql-tag';
import { format, parseISO } from 'date-fns';

const createResultCommentMutation = gql`
mutation createResultComment($input: CreateResultCommentInput!) {
  createResultComment(input: $input) {
    schedule {
      id
      staffComments {
        id
        content
        createdAt
        authorName
      }
    }
    errors {
      message
      path
    }
  }
}
`;

export default {
  props: {
    scheduleId: {
      type: String,
      required: true
    },
    comments: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    newComment: '',
  }),
  methods: {
    formatTimestamp: function(value) {
      return format(parseISO(value), 'yyyy-MM-dd HH:mm');
    },
    submitComment: function() {
      this.$apollo.mutate({
        mutation: createResultCommentMutation,
        variables: {
          input: {
            id: this.scheduleId,
            comment: {
              content: this.newComment,
            }
          }
        }
      }).then((response) => {
        const err = response.errors || response.data.createResultComment.errors

        if (err.length) {
          console.error(err);
          return;
        }

        this.newComment = '';
      }).catch((error) => {
        console.error(error);
      })
    },
  },
}
</script>
