<template>
  <div v-if="isVisible" class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

    <transition
      v-show="isVisible"
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
    </transition>

    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <transition
      v-show="isVisible"
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0 translate-y-4 translate-y-0 scale-95"
      enter-to-class="opacity-100 translate-y-0 scale-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100 translate-y-0 scale-100"
      leave-to-class="opacity-0 translate-y-4 translate-y-0 scale-95"
    >
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="px-4 pt-6">
          <div class="mx-auto flex items-center justify-center h-14 w-14 rounded-full bg-indigo-100">
            <i class="fas fa-wrench fa-lg text-indigo-600"></i>
          </div>
          <div class="mt-3 sm:mt-5 mx-auto">
            <h3 class="text-lg leading-6 font-medium text-center text-gray-900" id="modal-headline">
              Resolve The Following Issue
            </h3>
            <div class="mt-6 mx-auto p-2 border-2 border-dashed border-red-300 bg-red-50 rounded text-left">
              <p class="text-sm text-red-700">
                {{ issueMessage }}
              </p>
            </div>

            <div
              v-if="canResolve === true"
              class="mt-6"
            >
              <div class="flex justify-between">
                <label for="staff_comment" class="block text-sm font-medium leading-5 text-gray-700">Comment (required)</label>
                <span class="text-sm leading-5 text-gray-500">Max. 80 characters</span>
              </div>
              <div class="mt-1 relative rounded-md">
                <textarea
                  v-model.trim="issueResolutionComment"
                  id="expense_comment"
                  maxlength="80"
                  rows="2"
                  class="form-textarea block w-full h-14 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                </textarea>
                <div class="flex items-center">
                  <span class="text-xs leading-5 text-gray-500">{{ issueResolutionComment.length }}/80 characters used</span>
                  <span v-if="issueResolutionComment.length > 79" class="text-xs italic leading-5 text-blue-500 pl-3">Concision is key!</span>
                  <span v-if="issueResolutionComment.length > 79" class="pl-2 pt-1">&#128521;</span>
                </div>
              </div>
            </div>
            <div
              v-else
              class="mt-6 flex"
            >
              <p>You can not resolve this issue manually and must make adjustments to the schedule, run, and/or reimbursement data to clear this issue.</p>
            </div>
          </div>
        </div>

        <div class="bg-gray-50 mt-6 px-4 py-6 flex flex-row items-center justify-between">
          <button type="button" @click="closeDialog" class="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-3 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-sm">
            Close
          </button>
          <button v-if="canResolve" @click="confirmDialog" type="button" class="inline-flex justify-center px-3 py-1 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Record Comment & Resolve Issue
          </button>
        </div>
      </div>
    </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    canResolve: {
      type: Boolean,
      required: true
    },
    issueMessage: {
      type: String,
      required: true
    },
    issueId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      issueResolutionComment: '',
    }
  },
  methods: {
    confirmDialog: function() {
      this.$emit("confirmDialog", this.issueResolutionComment);
      this.closeDialog();
    },
    closeDialog: function() {
      this.$emit("closeDialog");
    },
  },
}
</script>
