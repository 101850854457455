<template>
<div class="divide-y divide-gray-300">
    <div :class="dataRowBtnContainer">
      <button
        @click="enableEdit()"
        v-show="!isEditEnabled"
        type="button"
        :class="editSaveBtn">
        Edit General Information
      </button>
      <button
        @click="cancelEdit()"
        v-show="isEditEnabled"
        type="button"
        :class="cancelBtn"
        class="mr-3"
      >
        Cancel
      </button>
      <button
        @click="save()"
        v-show="isEditEnabled"
        type="button"
        :class="editSaveBtn">
        Save
      </button>
    </div>
    <div :class="dataRowContainer">
      <div :class="dataRowTitle">First Name</div>
      <div :class="dataRowContent">
        <input
          type="text"
          :disabled="!isEditEnabled"
          :class="isEditEnabled? inputFieldActive : inputFieldInactive"
          v-model.trim="person.firstName" />
      </div>
    </div>
    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Middle Name</div>
      <div :class="dataRowContent">
        <input
          type="text"
          :disabled="!isEditEnabled"
          :class="isEditEnabled? inputFieldActive : inputFieldInactive"
          v-model.trim="person.middleName" />
      </div>
    </div>
    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Last Name</div>
      <div :class="dataRowContent">
        <input
          type="text"
          :disabled="!isEditEnabled"
          :class="isEditEnabled? inputFieldActive : inputFieldInactive"
          v-model.trim="person.lastName" />
      </div>
    </div>
    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Date Of Birth</div>
      <div :class="dataRowContent">
        <!-- using plain text, as I couldn't find
             a good way to make this non-interactable -->
        <span
          v-if="!isEditEnabled"
          class="text-sm font-normal leading-5"
        >
          {{ person.dateOfBirth }}
        </span>
        <flat-pickr v-else
          v-model="person.dateOfBirth"
          :config="flatPickrDateConfig"
          :clickOpens="false"
          :class="isEditEnabled? inputFieldActive : inputFieldInactive">
        </flat-pickr>
        </div>
    </div>
    <div :class="dataRowContainer">
      <div :class="dataRowTitle">Social Security Number</div>
      <div :class="dataRowContent" class="relative rounded-md">
        <div class="absolute inset-y-0 left-0 pl-1 flex items-center pointer-events-none">
          <span v-if="person.ssnLastFour">***-**-{{ person.ssnLastFour }}</span>
          <span v-else>No SSN for this person</span>
        </div>
      </div>
    </div>
    <template v-if="showSuccessNotification">
      <transition
        enter-active-class="transition ease-out duration-400 transform"
        enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <notification-success>User data has been updated</notification-success>
      </transition>
    </template>
  </div>
</template>

<script>
import notificationSuccess from './../../../shared-components/notifications/notification-success';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import gql from 'graphql-tag';

const updatePersonMutation = gql`
mutation updatePerson($input: UpdatePersonInput!) {
  updatePerson(input: $input) {
    person {
      id
      firstName
      middleName
      lastName
      dateOfBirth
      ssnLastFour
    }
    errors {
      message
      path
    }
  }
}
`;

export default {
  components: { notificationSuccess, flatPickr },
  props: {
    person: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    isEditEnabled: false,
    showSuccessNotification: false,
    flatPickrDateConfig: {
      enableTime: false,
      dateFormat: 'Y-m-d',
      allowInput: true,
      altFormat: 'm/d/Y',
      altInput: true,
    },
  }),
  computed: {
    dataRowContainer() {
      return ['grid', 'grid-cols-4', 'gap-4', 'p-2', 'flex', 'items-center']
    },
    dataRowTitle() {
      return ['col-span-1', 'leading-5', 'text-sm', 'text-gray-500', 'font-medium']
    },
    dataRowContent() {
      return ['col-span-2', 'leading-5', 'text-sm', 'text-gray-900', 'font-medium']
    },
    dataRowBtnContainer() {
      return ['col-span-1', 'flex', 'justify-end', 'py-2']
    },
    inputFieldActive() {
      return ['form-input', 'block', 'w-full', 'text-sm', 'leading-5']
    },
    inputFieldInactive() {
      return ['bg-white', 'block', 'w-full', 'text-sm', 'leading-5']
    },
    editSaveBtn() {
      return ['shadow-sm', 'rounded', 'border', 'border-gray-300', 'bg-white', 'px-2', 'py-1', 'inline-flex', 'items-center', 'text-xs', 'leading-3', 'font-medium', 'text-gray-700', 'hover:text-gray-500', 'focus:outline-none', 'active:bg-gray-100', 'active:text-gray-700', 'transition', 'ease-in-out', 'duration-150']
    },
    cancelBtn() {
      return ['px-2', 'py-1', 'inline-flex', 'items-center', 'text-xs', 'leading-3', 'font-medium', 'text-red-600', 'hover:underline', 'focus:outline-none', 'active:text-red-800', 'transition', 'ease-in-out', 'duration-150']
    },
  },
  methods: {
    cancelEdit: function() {
      this.isEditEnabled = false;
    },
    enableEdit: function() {
      this.isEditEnabled = true;
    },
    save: function() {
      // TODO: need to perform validation
      this.$apollo.mutate({
        mutation: updatePersonMutation,
        variables: {
          input: this.buildInput(),
        }
      }).then((response) => {
        const err = response.data.errors ??
                    response.data?.updatePerson?.errors;
        if (err.length) {
          console.error(err);
          return;
        }

        this.isEditEnabled = false;
        this.showSuccessNotification = true;
        setTimeout(() => this.showSuccessNotification = false, 3000);
      })
    },
    buildInput: function() {
      return {
        id: this.person.id,
        person: {
          firstName: this.person.firstName,
          middleName: this.person.middleName,
          lastName: this.person.lastName,
          dateOfBirth: this.person.dateOfBirth,
        }
      };
    },
  },
}
</script>