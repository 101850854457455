<template>
  <div class="px-6 overflow-x-hidden">
    <div
      v-if="vehicleActionMessage"
      class="flex items-center justify-between bg-blue-100 rounded border-2 border-blue-300 py-1 px-3 mb-2">
      <div class="flex text-sm">
        <i class="fad fa-dog fa-lg text-gray-700 mr-3"></i>
        <p class="text-blue-900 font-medium">
          Enzo updated the vehicle!
        </p>
      </div>
      <div class="">
        <button
          @click="toggleVehicleActionMessage()"
          type="button"
          class="flex p-2 rounded-md hover:bg-green-500 focus:outline-none focus:bg-green-500 transition ease-in-out duration-150"
        >
          <i class="fal fa-times fa-lg text-green-800"></i>
        </button>
      </div>
    </div>

    <div class="flex flex-col mt-3">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border-2 border-gray-200">
          <table class="min-w-full">
            <thead>
              <tr>
                <th class="px-6 py-3 border-b border-gray-200 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  License Plate
                </th>
                <th class="px-6 py-3 border-b border-gray-200 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  Year
                </th>
                <th class="px-6 py-3 border-b border-gray-200 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  Make
                </th>
                <th class="px-6 py-3 border-b border-gray-200 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  Model
                </th>
                <th class="px-6 py-3 border-b border-gray-200 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  Status
                </th>
                <th class="px-6 py-3 border-b border-gray-200 bg-gray-200 text-right">
                  <a
                    :href="`/users/${this.driverId}/vehicles/new`"
                    class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-800 bg-indigo-200 hover:bg-indigo-300 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200 transition ease-in-out duration-150"
                  >
                    Add Vehicle
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="vehicle in vehicles" :key="vehicle.id" class="bg-white">
                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  {{vehicle.licensePlate}}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  {{vehicle.year}}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  {{vehicle.make}}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  {{vehicle.model}}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                  <span v-if="vehicle.status === 'ACTIVE'" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    {{vehicle.status}}
                  </span>
                  <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    {{vehicle.status}}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                  <a v-if="vehicle.status === 'ACTIVE'"
                    :href="`/users/${driverId}/vehicles/${vehicle.id}/edit`"
                    class="text-indigo-600 hover:text-indigo-900  hover:underline focus:outline-none mr-5"
                  >
                  Edit
                  </a>
                  <button v-if="vehicle.status === 'ACTIVE'"
                    @click.prevent="archive(vehicle.id)"
                    class="text-red-600 hover:text-red-900  hover:underline focus:outline-none"
                  >
                  Archive
                  </button>
                  <button v-if="vehicle.status === 'ARCHIVED'"
                    @click.prevent="reactivate(vehicle.id)"
                    class="text-green-600 hover:text-green-900  hover:underline focus:outline-none"
                  >
                  Re-activate
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
  import gql from 'graphql-tag';

  const updateVehicleStatusMutation = gql`
  mutation archiveVehicle($input: UpdateVehicleStatusInput!) {
    updateVehicleStatus(input: $input) {
      vehicle {
        id
        status
      }
      errors
    }
  }`;

  export default {
    props: {
      vehicles: {
        type: Array,
        required: true
      },
      driverId: {
        type: String,
        required: true
      },
    },
    data: () => ({
      vehicleActionMessage: false,
    }),
    methods: {
      toggleVehicleActionMessage: function() {
        this.vehicleActionMessage = !this.vehicleActionMessage;
      },
      archive: function(vehicleId) {
        this.updateStatus('ARCHIVED', vehicleId)
      },
      reactivate: function(vehicleId) {
        this.updateStatus('ACTIVE', vehicleId)
      },
      updateStatus: function(status, id) {
        this.$apollo.mutate({
          mutation: updateVehicleStatusMutation,
          variables: {
            input: {
              driverId: this.driverId,
              vehicleId: id,
              status: status,
            }
          }
        }).then((response) => {
          const payload = response.data.updateVehicleStatus;
          const err = payload.errors
          if (err.length) {
            console.error("crap!")
            console.error(`We got error(s): ${err}`)
            return
          }
          this.toggleVehicleActionMessage()
        }).catch((error) => {
          console.error(error)
        })
      }
    },
  }
</script>
