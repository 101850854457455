<template>
  <div class="flex flex-col absolute top-0 bottom-0 left-0 right-0 bg-white">
    <template v-if="$apollo.loading">
      <apollo-loading></apollo-loading>
    </template>

    <template v-else>
      <div class="flex flex-col flex-grow min-h-0">

        <top-info-header
          :driver="schedule.driver.displayName"
          :date="schedule.date"
          :reimbursementId="schedule.reimbursement.id"
          :processedAt="schedule.reimbursement.processedAt"
          :needsReview="schedule.reimbursement.needsReview"
        >
        </top-info-header>

        <reimbursement-issues :issues="unresolvedIssues"></reimbursement-issues>
        <reimbursement-review
          v-if="schedule.reimbursement.needsReview"
          :reimbursementId="schedule.reimbursement.id"
        >
        </reimbursement-review>

        <!-- Page content section -->
        <div class="overflow-y-auto flex-grow min-h-0 px-6 pb-24 pt-6">
          <div class="flex space-x-2 mb-1">
            <h2 class="text-lg leading-6 font-medium text-gray-900">Driver's Run Data</h2>
            <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-pink-100 text-pink-800">
              {{ runCount }}
            </span>
            <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
              <i class="fab fa-android mr-2"></i>
              Android
            </span>
          </div>
          <div class="mb-8">
            <driver-runs
              :scheduleId="schedule.id"
              :runs="schedule.runs"
              :driverName="schedule.driver.displayName"
            >
            </driver-runs>
          </div>

          <div class="flex space-x-6 mb-4">
            <div class="flex-1">
              <driver-schedule :schedule="this.schedule"></driver-schedule>
              <div class="flex space-x-2 mt-8">
                <h2 class="text-lg leading-6 font-medium text-gray-900">Staff's Activity</h2>
                <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-pink-100 text-pink-800">
                  {{ allComments.length }} comment(s)
                </span>
              </div>
              <div class="mt-1">
                <staff-comments
                  :scheduleId="schedule.id"
                  :comments="allComments"
                >
                </staff-comments>
              </div>
            </div>
            <div class="flex-1">
              <reimbursement-data
                :reimbursement="schedule.reimbursement"
                :odometersAvailable="odometersAvailable"
              >
              </reimbursement-data>
            </div>
          </div>
         </div>
      </div>
    </template>

  </div>
</template>

<script>
  import apolloLoading from './../../shared-components/apollo-loading/apollo-loading';
  import gql from 'graphql-tag';
  import driverRuns from './components/driver-runs';
  import reimbursementData from './components/reimbursement-data';
  import staffComments from './components/staff-comments';
  import reimbursementIssues from './components/reimbursement-issues'
  import reimbursementReview from './components/reimbursement-review'
  import topInfoHeader from './components/top-info-header';
  import driverSchedule from './components/driver-schedule';

  const scheduleQuery = gql`
  query resultsForSchedule($id: ID!) {
    result:schedule(id: $id) {
      id
      date
      driver {
        id
        displayName
      }
      status
      totalDistance
      passengerDistance
      deadheadDistance
      clientCount
      stops {
        id
        kind
        performAt
        performAtUnspecified
        address
        result
        client {
          id
          listName
        }
        leg {
          programs {
            id
            kind
            name
            shortName
          }
        }
      }
      staffComments {
        id
        content
        createdAt
        authorName
      }
      reimbursement {
        id
        hasUnresolvedIssues
        effectiveDate
        processedAt
        totalDisplay
        drivingReimbursementDisplay
        expenseReimbursementDisplay
        needsReview
        reimbursementEdgeGroups {
          id
          startAddress
          endAddress
          distanceDisplay
          amountDisplay
          reimbursementEdges {
            id
            deadhead
            reimbursementRateDisplay
            distanceDisplay
            client {
              id
              displayName
            }
            program {
              id
              name
              shortName
            }
            strategy
          }
        }
        issues {
          id
          level
          kind
          reimbursement {
            id
          }
          isResolved
          resolvedAt
          updatedAt
          createdAt
          comment
          description
          isResolvable
        }
        reimbursementExpenses {
          id
          amount
          comment
        }
        comments {
          id
          content
          createdAt
          authorName
        }
      }
      runs {
        id
        startOdometer
        endOdometer
        latestSignature {
          getUrl
        }
        vehicle {
          id
          licensePlate
          make
          model
          year
        }
        checklistResponses {
          id
          unsatLines
          checklist {
            title
            items
          }
        }
        activities {
          id
          kind
          timestamp
          latitude
          longitude
          stop {
            id
            address
          }
        }
        odometerDelta
        runNotes {
          id
          content
          timestamp
        }
      }
    }
  }
  `;

  export default {
    components: {apolloLoading, reimbursementIssues, reimbursementReview, driverRuns, driverSchedule, reimbursementData, staffComments, topInfoHeader},
    data() {
      return {
        reimbursementProcessed: "processed",
      }
    },
    props: {
      id: {
          type: String,
          required: true
        }
      },
    apollo: {
      schedule: {
        query: scheduleQuery,
        variables() {
          return {
            id: this.id
          }
        },
        update: data => data.result,
      }
    },
    computed: {
      allIssues: function() {
        return this.schedule.reimbursement.issues // TODO: unnecessary prop
      },
      unresolvedIssues: function() {
        return this.allIssues.filter(i => !i.resolved)
      },
      runCount: function() {
        if (this.schedule.runs.length === 1) {
          return "1 run"
        }

        return `${this.schedule.runs.length} runs`
      },
      odometersAvailable: function() {
        return !!this.schedule.runs.length &&
          this.schedule.runs.every(r => r.odometerDelta > 0)
      },
      allComments: function() {
        return this.schedule.staffComments
          .concat(this.schedule.reimbursement.comments)
          .sort((a, b) =>
            a.createdAt > b.createdAt ? -1 : (a.createdAt > b.createdAt ? 1 : 0)
          )
      },
    }
  }
</script>
