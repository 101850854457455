<template>
<div>
  <div class="flex justify-between bg-blue-50 border-b border-t border-blue-300 px-2 py-2">
    <div class="flex items-center space-x-2 text-sm text-blue-700">
      <div class="flex-shrink-0">
        <i class="fas fa-times-circle h-5 w-5 text-blue-400"></i>
      </div>
      <p>This Reimbursement has marked for review. Before it can be processed please review it.</p>
    </div>
    <div class="self-end pr-4">
      <button
        @click.prevent="completeReview"
        type="button"
        class="inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-300 shadow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
      >
        Complete Review
      </button>
    </div>
  </div>
</div>
</template>

<script>
import gql from 'graphql-tag';

const resolveReviewMutation = gql`
mutation resolveReview($input: ResolveReviewInput!) {
  resolveReview(input: $input) {
    reimbursement {
      id
      needsReview
      comments {
        id
        content
        createdAt
        authorName
      }
    }
    errors
  }
}
`;

export default {
  props: {
    reimbursementId: {
      type: String,
      required: true
    },
  },
  methods: {
    completeReview: function() {
      this.$apollo.mutate({
        mutation: resolveReviewMutation,
        variables: {
          input: {
            reimbursementId: this.reimbursementId,
          }
        }
      }).then(response => {
        const err = response.errors || response.data.resolveReview.errors
        if (err.length) {
          console.error(err)
          // show error
          return
        }

        // show success/failure
      }).catch(error => {
        console.error(error)
      })
    }
  }
}
</script>
