<template>
<div>
  <header class="h-16 px-4 py-2 bg-gray-100 border-b border-gray-300">
    <div class="h-full flex items-center justify-between">
      <div class="flex">
        <div class="self-center">
          <span class="mr-3">
            <img
              class="inline-block h-10 w-10 rounded-md border border-white"
              src="https://tkt-adama-public.s3.amazonaws.com/generic_avatar_square.png"
              alt="" />
          </span>
        </div>
        <div class="text-gray-800">
          <span class="block text-xl">{{ driver }}</span>
          <span class="block">{{ date }}</span>
        </div>
      </div>
      <div class="flex flex-wrap items-center space-x-6">
        <span
          v-if="processedAt"
          class="inline-flex items-center px-3 py-2.5 rounded-md text-sm font-medium uppercase leading-4 bg-gray-100 text-gray-500 tracking-wide"
        >
          <i class="fas fa-exclamation-circle text-gray-400 mr-2"></i>
          Processed {{ processedAt }}
        </span>
        <!-- TODO: make button work for marking in rieview and hide if curently under review -->
        <button
          v-if="!this.needsReview"
          @click.prevent="markForReview"
          id="btn-review"
          title="Marking for review will require that another staff member review this reimbursement before it can be processed."
          class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md shadow text-gray-800 bg-gray-300 hover:bg-gray-400 focus:outline-none"
        >
          Mark for Review
        </button>
      </div>
    </div>
  </header>
</div>
</template>

<script>
import gql from 'graphql-tag';

const markForReviewMutation = gql`
mutation markForReview($input: MarkForReviewInput!) {
  markForReview(input: $input) {
    reimbursement {
      id
      needsReview
      comments {
        id
        content
        createdAt
        author {
          id
          displayName
        }
      }
    }
    errors
  }
}
`;

export default {
  props: {
    driver: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    reimbursementId: {
      type: String,
      required: true
    },
    processedAt: {
      type: String,
      required: false,
    },
    needsReview: {
      type: Boolean,
      required: true
    },
  },
  methods: {
    markForReview: function() {
      this.$apollo.mutate({
        mutation: markForReviewMutation,
        variables: {
          input: {
            reimbursementId: this.reimbursementId,
          }
        }
      }).then(response => {
        const err = response.errors || response.data.markForReview.errors
        if (err.length) {
          console.error(err)
          // show error
          return
        }

        // show success/failure
      }).catch(error => {
        console.error(error)
      })
    }
  },
}
</script>
