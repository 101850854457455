// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
import "@hotwired/turbo-rails"
// require("@rails/activestorage").start()

import 'styles/application.scss';
import '@fortawesome/fontawesome-pro/js/all.js';
import Vue from 'vue/dist/vue.esm'

Vue.config.productionTip = false;

import { provide } from '@vue/composition-api'
import { DefaultApolloClient } from '@vue/apollo-composable'
import apolloClient from 'apolloClient';

import VueCompositionApi from '@vue/composition-api';
import VueApollo from 'vue-apollo'
import VueFormulate from '@braid/vue-formulate'
import UserProfile from '../components/user-profile/user-profile.vue'
import VehiclesIndex from '../components/vehicles-index/vehicles-index.vue'
import VehicleForm from '../components/vehicle-form/vehicle-form.vue'
import Result from '../components/result/result.vue'

import { format, compareAsc } from 'date-fns'

import { Icon }  from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-realtime';

import TurbolinksAdapter from 'vue-turbolinks'

Vue.use(TurbolinksAdapter)
Vue.use(VueCompositionApi);
Vue.use(VueApollo)
Vue.use(VueFormulate)

// this can probably be removed after switching to composition API
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

Vue.component('user-profile', UserProfile)
Vue.component('vehicles-index', VehiclesIndex)
Vue.component('vehicle-form', VehicleForm)
Vue.component('result', Result)

// make required field validator available globally
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

// Not really thrilled about doing this, but webpacker configuration is different than webpack itself
// that it is difficult to determine the best practice for our use case. This will be revisited as we
// move away from Vue and reconsider our JavaScript setup.
import { TabulatorFull as Tabulator } from "tabulator-tables";
window.Tabulator = Tabulator;
import 'tabulator-tables/dist/css/tabulator.min.css'

document.addEventListener("turbo:load", function() {
  const vueElement = document.querySelector("[data-behavior='vue']")
  if (vueElement) {
    new Vue({
      setup() {
        provide(DefaultApolloClient, apolloClient)
      },
      el: vueElement,
      apolloProvider,
      // store,
    })
  }
});
