<template>
  <div class="divide-y divide-gray-300">

    <div :class="dataRowContainer" v-for="program in programs" :key="program.id">
      <div :class="dataRowTitle">{{ program.name }} ({{ program.shortName }})</div>
      <div :class="dataRowContent">
        <input type="checkbox"
          :name="`program${program.id}`"
          :checked="assignedProgramIds.includes(program.id)"
          @change="checkChanged"
        />
      </div>
    </div>

    <template v-if="showSuccessNotification">
      <transition
        enter-active-class="transition ease-out duration-400 transform"
        enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <notification-success>User data has been updated</notification-success>
      </transition>
    </template>
  </div>

</template>

<script>
  import notificationSuccess from './../../../shared-components/notifications/notification-success';
  import gql from 'graphql-tag'
  const programsQuery = gql`
    query programs {
      programs {
        id
        name
        shortName
      }
    }
  `;

  const updateClientProgramsMutation = gql`
    mutation setPersonClientProgram($input: SetPersonClientProgramInput!) {
      setPersonClientProgram(input:$input) {
        person {
          id
          clientPrograms {
            program {
              id
              name
              shortName
              kind
            }
          }
        }
        errors
      }
    }
  `;

  export default {
    components: { notificationSuccess },
    props: {
      person: {
        type: Object,
        required: true
      },
    },
    data: () => ({
      showSuccessNotification: false
    }),
    computed: {
      dataRowContainer() {
        return ['grid', 'grid-cols-4', 'gap-4', 'p-2', 'flex', 'items-center']
      },
      dataRowTitle() {
        return ['col-span-1', 'leading-5', 'text-sm', 'text-gray-500', 'font-medium']
      },
      dataRowContent() {
        return ['col-span-2', 'leading-5', 'text-sm', 'text-gray-900', 'font-medium']
      },
      dataRowBtnContainer() {
        return ['col-span-1', 'flex', 'justify-end', 'py-2']
      },
      assignedProgramIds() {
        return this.person.clientPrograms
          .map(clientProgram => clientProgram.program)
          .map(p => p.id)
      }
    },
    methods: {
      checkChanged: function(e) {
        const programId = e.target.name.match(/\d+/)[0]
        const isEnabled = e.target.checked

        this.$apollo.mutate({
          mutation: updateClientProgramsMutation,
          variables: {
            input: this.buildInput(programId, isEnabled)
          }
        }).then((response => {
          const err = response.errors || response.data.setPersonClientProgram.errors;
          if (err.length) {
            console.error(err)
            return;
          }

          this.showSuccessNotification = true;
          setTimeout(() => this.showSuccessNotification = false, 3000);
        }))
      },
      buildInput: function(programId, isEnabled) {
        return {
          personId: this.person.id,
          programId: programId,
          enabled: isEnabled
        }
      },
    },
    apollo: {
      programs: {
        query: programsQuery
      },
    }
  }
</script>
