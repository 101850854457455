<template>
  <div class="flex-auto overflow-y-auto">
    <div>

      <template v-if="$apollo.loading">
        <apollo-loading></apollo-loading>
      </template>

      <template v-else>
        <div>
          <div v-if="errors.length" class="bg-red-200 border-b-4 border-red-500 text-red-800 p-4 mb-2" role="alert">
            <p class="font-bold mb-2">The following issues occurred while creating this trip:</p>
            <div v-for="(erra, index) in errors" v-bind:key="index">
              <p>{{erra}}</p>
            </div>
          </div>
          <div class="max-w-3xl mx-auto py-8 px-4">
            <div class="max-w-4xl mx-auto">

              <div class="form-title-shell">
                <div class="form-title-text">
                  <h1 v-if="isNewVehicle" class="form-title-text">New Vehicle For {{ driverName }}</h1>
                  <h1 v-else class="form-title-text">Edit Vehicle For {{ driverName }}</h1>
                </div>
                <div class="flex items-center">
                  <div class="text-xl antialiased font-medium tracking-wide text-gray-900 opacity-75 capitalize mr-2">Show Tips</div>
                  <span
                    :class="showTips ? 'bg-indigo-600' : 'bg-gray-500'"
                    class="relative inline-block flex-no-shrink h-6 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
                    role="checkbox"
                    @click="showTips = !showTips"
                  >
                  <span
                      :class="{ 'translate-x-6': showTips, 'translate-x-0': !showTips }"
                      class="inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200">
                    </span>
                  </span>
                </div>
              </div>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onSubmit)">
                  <div class="shadow-lg rounded-md mt-6 border-t-8 border-blue-500">
                    <div class="px-8 pt-6 pb-2 bg-white">
                      <div class="form-input-shell">
                        <label for="vehicle_year" class="form-input-label">
                          <span>Year</span>
                        </label>
                        <ValidationProvider name="Year" rules="required" v-slot="{ errors }">
                          <v-select
                            v-model="vehicle.year"
                            :options="vehicleYears"
                            placeholder="Select year"
                            class="vue-select-custom">
                          </v-select>
                          <span class="validation-erra-text">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="px-8 py-2 bg-white">
                      <div class="form-input-shell">
                        <label for="vehicle_make" class="form-input-label">
                          <span>Make</span>
                        </label>
                        <ValidationProvider name="Vehicle make" rules="required" v-slot="{ errors }">
                          <input
                            id="vehicle_make"
                            class="text-input"
                            type="text"
                            v-model="vehicle.make"
                          />
                          <span class="validation-erra-text">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="px-8 py-2 bg-white">
                      <div class="form-input-shell">
                        <label for="vehicle_model" class="form-input-label">
                          <span>Model</span>
                        </label>
                        <ValidationProvider name="Vehicle model" rules="required" v-slot="{ errors }">
                          <input
                            id="vehicle_model"
                            class="text-input"
                            type="text"
                            v-model="vehicle.model"
                          />
                          <span class="validation-erra-text">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="px-8 py-2 bg-white">
                      <div class="form-input-shell">
                        <label for="vehicle_licensePlate" class="form-input-label">
                          <span>License Plate</span>
                        </label>
                        <ValidationProvider name="License plate" rules="required" v-slot="{ errors }">
                          <input
                            id="vehicle_licensePlate"
                            class="text-input"
                            type="text"
                            v-model="vehicle.licensePlate"
                        />
                          <span class="validation-erra-text">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="px-8 py-2 bg-white">
                      <div class="form-input-shell">
                        <label for="vehicle_isWheelchairVehicle" class="form-input-label">
                          <span>Wheelchair Vehicle?</span>
                        </label>
                        <input
                          id="vehicle_isWheelchairVehicle"
                          type="checkbox"
                          v-model="vehicle.isWheelchairVehicle"
                        />
                      </div>
                    </div>
                    <div class="px-8 py-4 bg-gray-100 text-right sm:px-6 rounded-b">
                      <span class="inline-flex rounded-md shadow-sm">
                        <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow text-sm leading-5 font-medium rounded-md text-white bg-blue-700 hover:bg-blue-500 focus:outline-none focus:border-blue-800 focus:shadow-outline-blue active:bg-blue-800 transition duration-150 ease-in-out">
                          Submit The Form
                        </button>
                      </span>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import apolloLoading from './../../shared-components/apollo-loading/apollo-loading';
  import gql from 'graphql-tag';
  import vSelect from 'vue-select';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { required } from 'vee-validate/dist/rules';
  import { getYear } from 'date-fns';

  const lookupDriverQuery = gql`
  query existingVehicle($driverId:ID!) {
    driver(id: $driverId){
      listName
    }
  }`;

  const existingVehicleQuery = gql`
  query existingVehicle($driverId:ID!, $vehicleId:ID!) {
    driver(id: $driverId){
      listName
      vehicle(id: $vehicleId){
        year
        make
        model
        licensePlate
        isWheelchairVehicle
      }
    }
  }`;

  const addVehicleMutation = gql`
  mutation addVehicle($input: AddVehicleInput!) {
    addVehicle(input: $input) {
      errors {
        message
        path
      }
    }
  }`;

  const updateVehicleMutation = gql`
  mutation updateVehicle($input: UpdateVehicleInput!) {
    updateVehicle(input: $input) {
      errors
    }
  }`;

  export default {
    components: { apolloLoading, ValidationObserver, ValidationProvider, vSelect },
    props: {
      vehicleId: {
        type: String,
        required: false
      },
      driverId: {
        type: String,
        required: true
      }
    },
    data: () => ({
      errors: [],
      showTips: false,
      driverName: null,
      vehicle: {
        licensePlate: null,
        year: null,
        make: null,
        model: null,
        isWheelchairVehicle: false,
      },
    }),
    apollo: {
      lookupDriver: {
        query: lookupDriverQuery,
        variables() {
          return {
            driverId: this.driverId,
          }
        },
        skip() {
          return !this.isNewVehicle;
        },
        update(data) {
          this.driverName = data.driver.listName
        }
      },
      existingVehicle: {
        query: existingVehicleQuery,
        variables() {
          return {
            driverId: this.driverId,
            vehicleId: this.vehicleId,
          }
        },
        skip() {
          return this.isNewVehicle;
        },
        update(data) {
          this.vehicle = data.driver.vehicle
          this.driverName = data.driver.listName
        }
      }
    },
    computed: {
      isNewVehicle: { get() { return !this.vehicleId; } },
      vehicleYears: { 
        get() {
          const firstYear = 1970; // TODO: query from organization
          const lastYear = getYear(new Date()) + 1;
          const size = (lastYear + 1) - firstYear
          return [...Array(size).keys()].map(i => i + firstYear)
        }
      },
    },
    methods: {
      onSubmit: function() {
        const mutationVariables = { 
          input: {
            driverId: this.driverId,
            vehicle: {
              licensePlate: this.vehicle.licensePlate,
              year: parseInt(this.vehicle.year),
              make: this.vehicle.make,
              model: this.vehicle.model,
              isWheelchairVehicle: this.vehicle.isWheelchairVehicle,
            }
          }
        }

        if (!this.isNewVehicle) {
          mutationVariables.input.vehicleId = this.vehicleId;
        }

        this.$apollo.mutate({
          mutation: this.isNewVehicle ? addVehicleMutation : updateVehicleMutation,
          variables: mutationVariables
        }).then((response) => {
          const payload = response.data.addVehicle ?? response.data.updateVehicle;
          const err = payload.errors
          if (err.length) {
            console.error(err);
            this.errors = err;
            return;
          }

          Turbo.clearCache();
          Turbo.visit(`/users/${this.driverId}/`);
        }).catch((error) => {
          console.error(error)
        })
      },
    }
  }
</script>
