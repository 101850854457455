<template>
    <div>
      <div class="pb-2 pl-4 inline-flex rounded-md shadow-sm">
        <button @click="toggleAll()" :class="showCollapseBtn">
          <span v-if="this.anyOpenSections">Collapse All</span>
          <span v-else>Show All</span>
        </button>
      </div>

      <div :class="sectionHeader">
        <button @click="toggleGeneralInformation" :class="sectionHeaderToggleBtn" type=button>
          <span v-show="sections.showGeneralInformation"><i :class="sectionHeaderUpCaretIcon"></i></span>
          <span v-show="!sections.showGeneralInformation"><i :class="sectionHeaderDownCaretIcon"></i></span>
          <h3 :class="sectionHeaderText">General Information</h3>
        </button>
      </div>
      <general-information
        v-show='sections.showGeneralInformation'
        :person="this.person"
        :class="sectionContentTemplate"
      >
      </general-information>

      <div :class="sectionHeader">
        <button @click="toggleContactInformation" :class="sectionHeaderToggleBtn" type=button>
          <span v-show="sections.showContactInformation"><i :class="sectionHeaderUpCaretIcon"></i></span>
          <span v-show="!sections.showContactInformation"><i :class="sectionHeaderDownCaretIcon"></i></span>
          <h3 :class="sectionHeaderText">Contact Information</h3>
        </button>
      </div>
      <contact-information
        v-show='sections.showContactInformation'
        :person="this.person"
        :class="sectionContentTemplate"
      >
      </contact-information>

      <div :class="sectionHeader">
        <button @click="toggleDemographicData" :class="sectionHeaderToggleBtn" type=button>
          <span v-show="sections.showDemographicData"><i :class="sectionHeaderUpCaretIcon"></i></span>
          <span v-show="!sections.showDemographicData"><i :class="sectionHeaderDownCaretIcon"></i></span>
          <h3 :class="sectionHeaderText">Demographic Data</h3>
        </button>
      </div>
      <demographic-data
        v-show='sections.showDemographicData'
        :person="this.person"
        :class="sectionContentTemplate"
      >
      </demographic-data>

      <div :class="sectionHeader">
        <button @click="toggleClientData" :class="sectionHeaderToggleBtn" type=button>
          <span v-show="sections.showClientData"><i :class="sectionHeaderUpCaretIcon"></i></span>
          <span v-show="!sections.showClientData"><i :class="sectionHeaderDownCaretIcon"></i></span>
          <h3 :class="sectionHeaderText">Client Data</h3>
        </button>
      </div>
      <client-data
        v-show='sections.showClientData'
        :person="this.person"
        :class="sectionContentTemplate"
      >
      </client-data>

      <div :class="sectionHeader">
        <button @click="showClientPrograms" :class="sectionHeaderToggleBtn" type=button>
          <span v-show="sections.showClientPrograms"><i :class="sectionHeaderUpCaretIcon"></i></span>
          <span v-show="!sections.showClientPrograms"><i :class="sectionHeaderDownCaretIcon"></i></span>
          <h3 :class="sectionHeaderText">Client Programs</h3>
        </button>
      </div>
      <client-programs
        v-show='sections.showClientPrograms'
        :person="this.person"
        :class="sectionContentTemplate"
      >
      </client-programs>

      <div :class="sectionHeader">
        <button @click="toggleInternalComments" :class="sectionHeaderToggleBtn" type=button>
          <span v-show="sections.showInternalComments"><i :class="sectionHeaderUpCaretIcon"></i></span>
          <span v-show="!sections.showInternalComments"><i :class="sectionHeaderDownCaretIcon"></i></span>
          <h3 :class="sectionHeaderText">Internal Staff Comments</h3>
        </button>
        <span v-show="this.person.internalNotes.length" class="inline-flex items-center ml-3 px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-purple-200 text-purple-900">
          {{ commentPillCaption }}
        </span>
      </div>
      <internal-comments
        v-show='sections.showInternalComments'
        :userId="this.person.id"
        :comments="this.person.internalNotes"
        :class="sectionContentTemplate"
      >
      </internal-comments>

    </div>
</template>

<script>
import clientData from './client-data';
import clientPrograms from './client-programs';
import contactInformation from './contact-information';
import demographicData from './demographic-data'
import generalInformation from './general-information';
import internalComments from './internal-comments';

export default {
  components: { clientData, clientPrograms, contactInformation, demographicData, generalInformation, internalComments },
  props: {
    person: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    sections: {
      showInternalComments: false,
      showClientData: false,
      showClientPrograms: false,
      showDemographicData: false,
      showProgramData: false,
      showContactInformation: false,
      showGeneralInformation: true,
    }
  }),
  methods: {
    toggleContactInformation: function() {
      this.sections.showContactInformation = !this.sections.showContactInformation;
    },
    toggleClientData: function() {
      this.sections.showClientData = !this.sections.showClientData;
    },
    showClientPrograms: function() {
      this.sections.showClientPrograms = !this.sections.showClientPrograms;
    },
    toggleDemographicData: function() {
      this.sections.showDemographicData = !this.sections.showDemographicData;
    },
    toggleGeneralInformation: function() {
      this.sections.showGeneralInformation = !this.sections.showGeneralInformation;
    },
    toggleInternalComments: function() {
      this.sections.showInternalComments = !this.sections.showInternalComments;
    },
    toggleAll: function() {
      this.sections = Object.fromEntries(Object.entries(this.sections).map(([k, v]) => [k, !this.anyOpenSections]))
    },
  },
  computed: {
    sectionHeader() {
      return ['p-4', 'py-2', 'bg-gray-50', 'border-t-2', 'border-b-2', 'border-300', 'flex', 'items-center']
    },
    sectionHeaderToggleBtn() {
      return ['focus:outline-none', 'flex']
    },
    sectionHeaderUpCaretIcon() {
      return ['fas', 'fa-caret-up', 'fa-lg', 'text-blue-900']
    },
    sectionHeaderDownCaretIcon() {
      return ['fas', 'fa-caret-down', 'fa-lg', 'text-blue-900']
    },
    sectionHeaderText() {
      return ['ml-3', 'text-base', 'leading-6', 'font-semibold', 'text-blue-900']
    },
    sectionContentTemplate() {
      return ['px-6']
    },
    commentPillCaption() {
      const qty = this.person?.internalNotes?.length || 0;
      return `${qty} ${ qty == 1 ? 'comment' : 'comments' }`;
    },
    anyOpenSections() {
      return Object.values(this.sections).some(v => v === true)
    },
    showCollapseBtn() {
      return ['inline-flex', 'items-center', 'px-2.5', 'py-1.5', 'border', 'border-transparent', 'text-xs', 'leading-4', 'font-medium', 'rounded', 'text-white', 'bg-blue-600', 'hover:bg-blue-500', 'focus:outline-none', 'transition', 'ease-in-out', 'duration-150']
    }
  }
}
</script>
