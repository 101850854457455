<template>
  <div class="flex-auto overflow-hidden mb-8">
    <template v-if="$apollo.loading">
      <apollo-loading></apollo-loading>
    </template>
    <template v-else>
      <div>
      </div>
    </template>
  </div>
</template>

<script>
  import apolloLoading from './../../shared-components/apollo-loading/apollo-loading';

  export default {
    components: { apolloLoading },
    }
</script>