<template>
<div>
  <!-- TODO: add class conditional styles for warning and info -->
  <div
  v-for="issue in issues" :key="issue.id"
  class="flex justify-between bg-red-50 border-b border-t border-red-300 px-2 py-2"
  >
    <div class="flex items-center space-x-2 text-sm text-red-700">
      <div class="flex-shrink-0">
        <i class="fas fa-times-circle h-5 w-5 text-red-400"></i>
      </div>
      <p>{{ issue.description }}</p>
    </div>
    <div class="self-end pr-4">
      <button
        @click="showModal(issue)"
        type="button"
        class="inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-300 shadow focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
      >
        resolve
      </button>
    </div>
  </div>

  <modal-issue-resolve
    :isVisible="modalExpenseIssueResolveVisible"
    :canResolve="isResolvable"
    :issueMessage="issueMessage"
    :issueId="issueId"
    @closeDialog="closeModal"
    @confirmDialog="resolve"
  >
  </modal-issue-resolve>
</div>
</template>

<script>
import gql from 'graphql-tag';
import modalIssueResolve from './modal-issue-resolve';

const resolveIssueMutation = gql`
mutation resolveIssue($input: ResolveIssueInput!) {
  resolveIssue(input: $input) {
    reimbursement {
      id
      hasUnresolvedIssues
      issues {
        id
        isResolved
        resolvedAt
        isResolvable
      }
    }
    errors
  }
}
`;

export default {
  components: { modalIssueResolve },
  props: {
    issues: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    modalExpenseIssueResolveVisible: false,
    isResolvable: true,
    reimbursementId: null, // hacky, need the reimbursementId for the issue being opened
    issueId: '',
    issueMessage: '',
  }),
  methods: {
    showModal: function(issue) {
      this.reimbursementId = issue.reimbursement.id
      this.issueId = issue.id
      this.issueMessage = issue.description
      this.isResolvable = issue.isResolvable
      this.modalExpenseIssueResolveVisible = true
    },
    closeModal: function() {
      this.modalExpenseIssueResolveVisible = false
    },
    resolve: function(resolutionComment) {
      this.$apollo.mutate({
        mutation: resolveIssueMutation,
        variables: {
          input: {
            reimbursementId: this.reimbursementId,
            id: this.issueId,
            comment: resolutionComment,
          }
        }
      }).then(response => {
        const err = response.errors || response.data.resolveIssue.errors
        if (err.length) {
          console.error(err)
          // show error
          return
        }

        // show success/failure
      }).catch(error => {
        console.error(error)
      })
    }
  }
}
</script>
