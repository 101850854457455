<template>
  <div class="flex border border-gray-300">
    <div class="w-2/5 h-144 overflow-auto">
      <div v-if="runs.length">
        <div
          v-for="run in runs"
          v-bind:key="run.id"
          class="mb-3 mt-2 px-2 py-1"
        >
          <div class="mb-2">
            <div class="text-xs font-semibold font-mono leading-4 text-center text-gray-700 border-t border-gray-300 py-1">
              {{ vehicleDisplay(run.vehicle) }}
              <!-- TODO: Add VIN number here -->
            </div>
            <div class="text-xs font-semibold font-mono leading-4 text-center text-gray-700 border-t border-gray-300 py-1">
              <p v-if="run.startOdometer">Start Odometer: {{ run.startOdometer }}</p>
              <p v-else>No Start Odometer Provided</p>
            </div>
            <div
              v-for="activity in run.activities"
              v-bind:key="activity.id"
              class="flex flex-col border-t last:border-b border-gray-300"
            >
              <div class="flex-grow items-center justify-start grid grid-rows grid-cols-6 grid-flow-col gap-2 py-1">

                <div class="col-start-1 col-span-1">
                  <span class="text-xs font-semibold font-mono leading-4 text-gray-700">
                    {{ formatTimestamp(activity.timestamp) }}
                  </span>
                </div>

                <div class="col-start-2 col-span-1 truncate">
                  <span class="text-xs leading-4 text-gray-700">
                    {{ formatActivityKind(activity.kind) }}
                  </span>
                </div>

                <div class="col-start-3 col-span-3 truncate">
                  <span class="text-xs leading-4 text-gray-700">
                    &nbsp; <!-- was client name -->
                  </span>
                </div>

                <!-- TODO: edit button? -->
                <!-- <div class="col-start-6 col-span-1 truncate">
                  <button class="shadow-sm inline-flex items-center px-2 py-1 rounded border border-gray-300 bg-white text-xs leading-3 font-medium text-gray-700 hover:text-gray-500 focus:outline-none active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                    edit
                  </button>
                </div> -->

                <div class="col-start-6 col-span-1">
                  <button
                    @click="focusMapToActivity({latitude:activity.latitude, longitude:activity.longitude })"
                    :class="(activity.latitude === 0 || activity.longitude === 0)? ['cursor-not-allowed','opacity-50'] : ['cursor-auto','hover:text-gray-500','active:bg-gray-100','active:text-gray-700']"
                    class="shadow-sm inline-flex items-center px-2 py-1 rounded border border-gray-300 bg-white text-xs leading-3 font-medium text-gray-700 focus:outline-none transition ease-in-out duration-150"
                  >
                    map
                  </button>
                </div>
              </div>
              <div
                v-if="fetchBreadcrumbs"
                class="flex justify-center border-t border-gray-300 bg-blue-50 py-2"
              >
                <button
                  class="text-xs font-semibold font-mono leading-4 text-blue-600 hover:underline"
                >
                  <span>show breadcrumbs</span>
                </button>
              </div>
            </div>
            <div class="text-xs font-semibold font-mono leading-4 text-center text-gray-700 border-t border-b border-gray-300 py-1">
              <p v-if="run.endOdometer">End Odometer: {{ run.endOdometer }}</p>
              <p v-else>No End Odometer Provided</p>
            </div>
          </div>

          <div v-if="run.checklistResponses">
            <div
              class="mt-2"
              v-for="response in run.checklistResponses"
              v-bind:key="response.id"
            >
              <!-- TODO: style ad complete checklist section, checklist missing v-else section is complete -->
              <h4 class="font-medium text-sm text-blue-800 pt-3">{{ response.checklist.title }}</h4>
              <div
                v-for="(item, idx) in response.checklist.items"
                v-bind:key="item"
                class="flex items-start">
                <template v-if="response.unsatLines && response.unsatLines.includes(idx)">
                  <i class="fad fa-times-square text-red-800 mr-2"></i>
                  <span class="font-light text-xs text-red-800">{{ item }}</span>
                </template>
                <template v-else>
                  <i class="fad fa-check-square text-blue-800 mr-2"></i>
                  <span class="font-light text-xs text-blue-800">{{ item }} </span>
                </template>
              </div>
            </div>
          </div>
          <div v-else class="border border-dashed border-gray-400 bg-gray-100 p-2 mt-2">
            <div class="flex">
              <div class="flex-shrink-0 self-center">
                <i class="fas fa-ghost fa-lg text-gray-500"></i>
              </div>
              <div class="ml-3">
                <p title="Checklist may have not been required or there could be a network delay" class="text-gray-700 text-sm font medium">No checklist found</p>
              </div>
            </div>
          </div>

          <div v-if="run.runNotes.length" class="flex flex-col bg-gray-50">
            Driver notes
            <div class="overflow-y-auto h-64 px-2 space-y-1">
              <div v-for="note in run.runNotes" :key="note.id" class="p-2">
                <div class="min-w-0 flex-1">
                  <div class="flex">
                    <p class="text-sm font-medium text-gray-900">{{ driverName }}</p>
                    <p class="text-xs pl-3 mt-0.5 text-gray-500 italic">{{ formatTimestamp(note.timestamp) }}</p>
                  </div>
                  <div class="mt-1 text-xs text-gray-700">
                    <p class="pl-3">{{ note.content }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Could change this in the future -->
          <div v-if="run.latestSignature && hasRunEndActivity(run.id)" class="bg-green-100 border border-green-400 p-4 mt-2">
            <!-- TODO: style sig area, sig not found is finished -->
            <img v-bind:src="run.latestSignature.getUrl" />
          </div>
          <div v-else class="border border-dashed border-gray-400 bg-gray-100 p-2 mt-2">
            <div class="flex">
              <div class="flex-shrink-0 self-center">
                <i class="fas fa-ghost fa-lg text-gray-500"></i>
              </div>
              <div class="ml-3">
                <p title="Driver may have forgotten to sign, or there is a network delay" class="text-gray-700 text-sm font medium">Signature is missing!</p>
              </div>
            </div>
          </div>

        </div>
        <div class="flex flex-row-reverse justify-start my-4 px-4">
          <div>
            <button
              @click="requestBreadcrumbs"
              type="button"
              :class="fetchBreadcrumbs === false? ['cursor-auto','hover:bg-blue-200','focus:ring-blue-500','focus:ring-2','focus:ring-offset-2'] : ['cursor-not-allowed','opacity-50']"
              class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-blue-700 bg-blue-100 focus:outline-none">
              Request Breadcrumbs
            </button>
          </div>
        </div>
      </div>
      <div v-else class="flex flex-col justify-center items-center border-2 border-dashed border-gray-400 bg-gray-100 text-gray-700 h-144 space-y-3">
        <i class="fas fa-ghost fa-2x text-gray-500"></i>
        <p class="font-thin px-8 text-center">No driver runs have been recorded</p>
        <p class="font-thin px-8 text-center">The driver has not started, or may be experiencing connectivity issues</p>
      </div>
    </div>
    <div class="w-3/5 h-144">
      <keep-alive>
        <runMap
          :mapCenter="mapCenter"
          :activities="allActivity"
          :breadcrumbs="breadcrumbs"
          ref="map"
        >
        </runMap>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import gql from 'graphql-tag'
import runMap from './run-map';

// could rewrite query, but for now we want all breadcrumbs for all runs
const fetchBreadcrumbs = gql`
  query schedule($id: ID!) {
    schedule(id: $id) {
      id
      runs {
        id
        breadcrumbs {
          id
          kind
          latitude
          longitude
          timestamp
        }
      }
	  }
  }`


export default {
  components: {runMap},
  data() {
    return {
      mapCenter: [43.975540, -75.906375],
      fetchBreadcrumbs: false,
      breadcrumbs: {
        runs: {
          breadcrumbs: null
        },
      },
      breadcrumbActivityObj: {}
    }
  },
  props: {
    scheduleId: {
      type: String,
      required: true
    },
    runs: {
      type: Array,
      required: true
    },
    driverName: {
      type: String,
      required: true
    }
  },
  apollo: {
    breadcrumbs: {
        query: fetchBreadcrumbs,
        update: data => data.schedule,
        variables() {
          return {
            id: this.scheduleId
          }
        },
        skip () {
          return this.fetchBreadcrumbs == false;
        },
      }
  },
  computed: {
    allActivity: function () {
      return this.runs.flatMap(r => r.activities)
    },
  },
  methods: {
    formatTimestamp: function(activityTimestamp) {
      return format(parseISO(activityTimestamp), 'HH:mm');
    },
    formatActivityKind: function(activityKind) {
      return activityKind.split('_').join(' ').toLowerCase();
    },
    vehicleDisplay: function(vehicle) {
      if (!vehicle) return "Vehicle not specified"
      return `${vehicle.year} ${vehicle.make} ${vehicle.model}, ${vehicle.licensePlate}`
    },
    focusMapToActivity(latLongObj) {
      if (latLongObj.latitude === 0 || latLongObj.longitude === 0) return;
      this.mapCenter = [latLongObj.latitude , latLongObj.longitude];
    },
    // TODO: take queried breadcrumbs and show them to the user when requested
    requestBreadcrumbs: function () {
      this.fetchBreadcrumbs = true;
      // this.breadcrumbs = breadcrumbs;
      // this.buildBreadcrumbActivityObj();
    },
    // buildBreadcrumbActivityObj() {
    //   const activities = this.allActivity();
      //sort activities by datetime
      // const hi = [];
      // console.log(this.breadcrumbs)
      // this.breadcrumbs.runs.forEach((run) => {
      //   breadcrumbs.push(...run.breadcrumbs)
      // });
      // console.log(hi);
    // }
    hasRunEndActivity: function(runId) {
      return this.runs.find(r => r.id === runId)
        .activities
        .some(a => a.kind == "RUN_END")
    }
  },
}
</script>
