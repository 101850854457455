<template>
  <div>
    <div class="bg-blue-200 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <i class="fas fa-info-circle text-blue-500"></i>
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm leading-5 text-blue-700">
            Individual user settings is a feature currently in the discussion phase.
          </p>
          <p class="text-sm leading-5 md:mt-0 md:ml-6">
            <a href="https://trello.com/c/v9iJ7o8I" target="_blank" rel="noopener noreferrer" class="whitespace-no-wrap font-medium text-blue-700 hover:text-blue-600 transition ease-in-out duration-150">
              Details &rarr;
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="ml-3 mt-3">
      <button
        @click.prevent="generateSetupPin"
        class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-black-800 bg-red-200 hover:bg-red-300 focus:outline-none focus:border-red-300 focus:shadow-outline-red active:bg-red-200 transition ease-in-out duration-150"
      >
        Create setup code for driver mobile application
      </button>
      <div v-if="this.pin">
        <div class="font-mono text-3xl tracking-widest">{{ pin }}</div>
        <div>This code will expire in <span>{{ expiry }}</span>.</div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDistanceToNow, parseISO } from 'date-fns';
import gql from 'graphql-tag'

const generateSetupTokenMutation = gql`
mutation generateSetupToken($input:GenerateSetupTokenInput!) {
  generateSetupToken(input:$input) {
    expiresAt
    pin
  }
}
`;

export default {
  props: {
    userId: {
      type: String,
      required: true
    },
  },
  data: () => ({
    pin: null,
    expiresAt: null,
  }),
  methods: {
    generateSetupPin: function() {
      this.$apollo.mutate({
        mutation: generateSetupTokenMutation,
        variables: {
          input: {
            userId: this.userId,
          }
        }
      }).then((response => {
        const payload = response.data.generateSetupToken
        if (payload) {
          this.pin = payload.pin
          this.expiry = formatDistanceToNow(parseISO(payload.expiresAt))
          return
        }
        console.error("Did not get response from mutation")
        console.error(response)
      })).catch((error) => {
        console.error(error)
      })
    }
  }
}
</script>
